
import React, { Component, createRef  } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import sidebarBGImage from "./assets/images/bg_1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";
import xlogo from "./assets/images/twitter-x-logo-42556.jpg"
import City from './City';
import CookieConsent from './CookieConsent';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

//function App() {
  class Footer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        content: '',
        cities: [
          "Aberdeen",
          "Glasgow",
          "Lancaster",
          "Brighton",
          "Cannock",
          "Telford",
          "Dudley",
          "Dundee",
          "Edinburgh",
          "Oxford",
          "Maidstone",
          "Redditch",
          "Derby",
          "Banbury",
          "Inverness",
          "Carlisle",
          "Crawley",
          "Wolverhampton",
          "Tamworth",
          "Stoke-on-Trent",
          "Birmingham", "Bristol", "Cardiff", "Coventry", "Exeter", "Leeds", "Leicester", "Liverpool", "London", 
          "Manchester", "Newcastle-upon-tyne", "Newport", "Northampton", "Norwich", "Nottingham", "Oxford", "Peterborough", 
          "Plymouth", "Sheffield", "Southampton", "Swansea", "Yeovil", "Walsall"
        ],
        search: '',
        selectedCity: null,
      };
      this.autocompleteRef = createRef();
    }

    handlePlaceSelect = () => {
      const place = this.autocompleteRef.current.getPlace();
      if (!place.geometry) {
        alert("No details available for input: '" + place.name + "'");
      } else {
        const locationName = place.name;
        this.navigateToCity(locationName);
      }
    };
    navigateToCity = (locationName) => {
      window.location.href = `/city/${locationName}`;
      //this.props.history.push(`/city/${locationName}`);
    };
  
    updateContent = (locationName) => {
      this.setState({
        content: `
          <h2>Same Day Courier at ${locationName}</h2>
          <p>Our dedicated, round the clock same day courier service at ${locationName} is available every day of the year...</p>
          <p>Parcel Lodge Ltd are ${locationName}'s most reliable same day courier service provider.</p>
          <p>Call us now: 0333 344 3028</p>
          <h2> Same Day Courier ${locationName}</h2>
          
          <p> We offer same day courier service in ${locationName} as well, we have transport offices in cities throughout the UK.</p>
          <p>If you need to book same day courier delivery in ${locationName}, our team will find the right size vehicle for you, no matter what size your package is, from important documents, parcels, pallets, etc to fragile items and construction equipment we can cover it all.</p>
          <p>Our friendly experienced drivers are the best in the business delivering a wide range of items and are fully insured.</p>
          <p>Parcel Lodge understands that parcels and packages come in all shapes and sizes, and understands that the need for protective, efficient parcel and item delivery is growing all the time. In a day and age where people expect to receive items quicker than ever before, we are proud to offer a lightning-quick same day courier service to get your essentials on the road sooner.</p>
          <p>We have over 12,000 drivers across the country so you can rest assured we can get to you in less than 1 hour and get your items delivered anywhere in the country today. When you need something picked up and delivered ASAP you can trust Parcel Lodge.</p>
          <p>If you would like an immediate quote or more information on ${locationName} same day courier service, you can call us on 0333 344 3028 or you can email us at info@collectsameday.co.uk. Alternatively you can complete the quote form.</p>
        `,
      });
    };
    // handleCityClick = (cityName) => {
    //   this.setState({ selectedCity: cityName });
    // };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value });
    }
  
    handleCityClick = (city) => {
      this.setState({ selectedCity: city });
    }

    render() {
      
      const { cities, search, selectedCity } = this.state;
    const filteredCities = cities.filter(city =>
      city.toLowerCase().includes(search.toLowerCase())
    );
    return (
      <>
        <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            {/* <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Cities</h2>
              <ul className="list-unstyled">
                {[
                  "Aberdeen",
                  "Glasgow",
                  "Lancaster",
                  "Brighton",
                  "Cannock",
                  "Telford",
                  "Dudley",
                  "Dundee",
                  "Edinburgh",
                  "Oxford",
                  "Maidstone",
                  "Redditch",
                  "Derby",
                  "Banbury",
                  "Inverness",
                  "Carlisle",
                  "Crawley",
                  "Wolverhampton",
                  "Tamworth",
                  "Stoke-on-Trent",
                  "Birmingham", "Bristol", "Cardiff", "Coventry", "Exeter", "Leeds", "Leicester", "Liverpool", "London", 
"Manchester", "Newcastle-upon-tyne", "Newport", "Northampton", "Norwich", "Nottingham", "Oxford", "Peterborough", 
"Plymouth", "Sheffield", "Southampton", "Swansea", "Yeovil"
                ].map((city, index) => (
                  <li className="py-2 inline-block" key={index}>
                    <a href={`/city/${city}`} onClick={() => this.handleCityClick(city)}>{city}</a>
                  </li>
                ))}
              </ul>
              {selectedCity && <City cityName={selectedCity} />}
            </div> */}

<div className="ftco-footer-widget mb-4">
        <h2 className="ftco-heading-2">Cities</h2>

        <LoadScript
        googleMapsApiKey="AIzaSyD7zLwxSWy5EdRoDYWii61pPVJEGRRZZH8"
        libraries={['places']}
      >
        <div>
          <label className="label" htmlFor="autocomplete">Find a location in the UK</label>
          <div className="search-field">
            <Autocomplete
              onLoad={(autocomplete) => this.autocompleteRef.current = autocomplete}
              onPlaceChanged={this.handlePlaceSelect}
              options={{ componentRestrictions: { country: 'uk' } }} // Restrict to UK only
            >
              <input
                id="autocomplete"
                placeholder="Enter a location"
                className="autocomplete-input"
                type="text"
              />
            </Autocomplete>
          </div>
          <div id="content" className="content" dangerouslySetInnerHTML={{ __html: this.state.content }} />
        </div>
      </LoadScript>
        {/* <input 
          type="text" 
          value={search} 
          onChange={this.handleSearchChange} 
          placeholder="Search cities..." 
          className="form-control mb-3 city-auto-search"
        /> */}
        <ul className="list-unstyled">
          {filteredCities.map((city, index) => (
            <li className="py-2 inline-block" key={index}>
              <a href={`/city/${city}`} onClick={() => this.handleCityClick(city)}>{city}</a>
            </li>
          ))}
        </ul>
        {selectedCity && <City cityName={selectedCity} />}
      </div>

          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2">Information</h2>
              <ul className="list-unstyled">
                <li><a href="/about" className="py-2 d-block">About</a></li>
                <li><a href="/becomedriver" className="py-2 d-block">Become a Driver</a></li>
                <li><a href="/terms" className="py-2 d-block">Term and Conditions</a></li>
                <li><a href="/cookiepolicy" className="py-2 d-block">Privacy & Cookies Policy</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md">
             <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Contact Us</h2>
              <ul className="list-unstyled">
              
                {/* <li><a href="/about" className="py-2 d-block">How it works</a></li> */}
                {/* <li><span className="icon icon-phone"></span><span className="text">+44 333 2244 839</span></li>
                  <li><span className="icon icon-phone"></span><span className="text">+44 7716 571824</span></li> */}
                  <li><span className="icon icon-phone"></span><span className="text">01218158330</span></li>
	                <li><span className="icon icon-envelope"></span><span className="text">sales@parcellodge.com</span></li>
              </ul>

             
            </div>

            <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2 zeromargin">Follow Us</h2>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-2">
              <li className="ftco-animate"><a href="https://x.com/LodgeParce82517"  target="_blank" rel="noreferrer"><svg className='twitterx' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></a></li>
                <li className="ftco-animate"><a href="https://www.facebook.com/profile.php?id=61563473687920"  target="_blank" rel="noreferrer"><span className="icon-facebook"></span></a></li>
                <li className="ftco-animate"><a href="https://www.instagram.com/parcellodge24/"  target="_blank" rel="noreferrer"><span className="icon-instagram"></span></a></li>
                <li className="ftco-animate"><a href="https://www.youtube.com/channel/UCsexKXTP6nnH-SMZt-fVJPQ" target="_blank" rel="noreferrer"><span className="icon-youtube"></span></a></li>
                <li className="ftco-animate"><a href="https://www.linkedin.com/company/parcel-lodge"  target="_blank" rel="noreferrer"><span className="icon-linkedin"></span></a></li>
                {/* <li className="ftco-animate"><a href="https://x.com/expressgravity?s=11"  target="_blank" rel="noreferrer"><span className="icon-twitter"></span></a></li>
                <li className="ftco-animate"><a href="https://www.facebook.com/GravitySpeedExpress?mibextid=LQQJ4d"  target="_blank" rel="noreferrer"><span className="icon-facebook"></span></a></li>
                <li className="ftco-animate"><a href="https://www.instagram.com/gravity_speed_express?igsh=MXVqMzZpOG11dGlkNQ=="  target="_blank" rel="noreferrer"><span className="icon-instagram"></span></a></li>
                <li className="ftco-animate"><a href="https://www.youtube.com/channel/UC0LoEkmS1iR7uJ585pcLWyw" target="_blank" rel="noreferrer"><span className="icon-youtube"></span></a></li>
                <li className="ftco-animate"><a href="https://www.linkedin.com/company/gravity-speed-express-ltd/"  target="_blank" rel="noreferrer"><span className="icon-linkedin"></span></a></li> */}
</ul>
</div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
            	<h2 className="ftco-heading-2">Head Office</h2>
            	<div className="block-23 mb-3">
	              <ul>
	                <li><span className="icon icon-map-marker"></span><span className="text">
                  {/* 20 WESTON STREET, <br></br>
WALSALL, <br></br>
WS1 4EJ <br></br>
WEST MIDLANDS <br></br>
UK. */}
                    20 Weston Street,<br></br> Walsall,<br></br> WS1 4EJ <br></br>West Midlands, Uk.</span></li>
	                
	              </ul>
	            </div>
            </div>

           

          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">

            <p>
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved
 </p>
 
          </div>
        </div>
      </div>
    </footer>
    
     <CookieConsent />
    </>
   
    )
    }
}
export default Footer;