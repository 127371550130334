import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";
import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";
import calendaricon from "./assets/images/calendar_icon.png";
import diffobj from "./assets/images/diffobj.png";
import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";
import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { vApis } from './Apis';
import MapContainer from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import industryImg from "./assets/images/image_6.jpg";
import IndustryBanner from './assets/images/contact-banner.png';
import calendaricon1 from "./assets/images/calendar_2997687.png";
import calendaricon2 from "./assets/images/clock_7027557.png";
import calendaricon3 from "./assets/images/distribution_13887543.png";
import calendaricon4 from "./assets/images/driver_8601681.png";
import calendaricon5 from "./assets/images/mobile_14241451.png";
import calendaricon6 from "./assets/images/Rectangle (1).png";
import calendaricon7 from "./assets/images/Rectangle (2).png";
import calendaricon8 from "./assets/images/Rectangle.png";
import calendaricon9 from "./assets/images/setting_6691010.png";
import calendaricon10 from "./assets/images/shield_6941222.png";
import calendaricon11 from "./assets/images/time-left_183300.png";
import ContactForm from './ContactForm';
import IndustryComponent from './IndustriesSection';

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceData: [],
      redirectToComingSoon: false,
    };
  }

  componentDidMount() {
    this.fetchService();
  }

  fetchService = async () => {
    console.log("fetchServices=====");
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    console.log("id here===", id);

    if (id === '5' || id==='6') {
      this.setState({ redirectToComingSoon: true });
      return;
    }

    const Service1 = await vApis.fetchData(`calculator/services/${id}/`);
    console.log("Service====", Service1);
    const serviceData = Service1?.data?.Service_data;
    console.log("servicedata4545===", serviceData);
    this.setState({ serviceData });
  };

  render() {
    const { serviceData, redirectToComingSoon } = this.state;

    if (redirectToComingSoon) {
      //return <Navigate to="/comingsoon" />;
      window.location.href = "/coming"
    }

    return (
      <div id="service-page">
        <Header />

        <section
          className="hero-wrap hero-wrap-2 js-fullheight"
          style={{
            backgroundImage: `url(${serviceData.image})`,
          }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay"></div>
          <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
              <div className="col-md-9 ftco-animate pb-5">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">
                      Home <i className="ion-ios-arrow-forward"></i>
                    </a>
                  </span>{" "}
                  <span>
                    Service <i className="ion-ios-arrow-forward"></i>
                  </span>
                  <span>{serviceData.Service}</span>
                </p>
                <h1 className="mb-3 bread">{serviceData.Service}</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="ftco-about">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-12 wrap-about ftco-animate">
                <div
                  dangerouslySetInnerHTML={{ __html: serviceData.description }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="ftco-section">
      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
          	{/* <span className="subheading">Industry</span> */}
            <h2>Why People Trust Us?</h2>
          </div>
        </div>
        </div>
        <div className='container'>
        <div className="row d-flex">
        {/* <IndustryComponent/> */}
        <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon} alt=""/>
                <h2 className="heading mt-4">Multi Drop Deliveries</h2>
                <div> 
                  <span>Efficient Route Planning</span>
                </div>
                <div> 
                  <span>Streamlined Operations</span>
                </div>
                <div> 
                  <span>Enhanced Customer Satisfaction</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon1} alt=""/>
                <h2 className="heading mt-4">Scheduled Booking</h2>
                <div> 
                  <span>Flexible scheduling</span>
                </div>
                <div> 
                  <span>Reliable planning </span>
                </div>
                <div> 
                  <span>Time saving convenience</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon2} alt=""/>
                <h2 className="heading mt-4">Secure payment</h2>
                <div> 
                  <span>Encrypted transactions</span>
                </div>
                <div> 
                  <span>Trusted payment gateway</span>
                </div>
                <div> 
                  <span>Fraud Prevention Measures</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon3} alt=""/>
                <h2 className="heading mt-4">24/7 customer service</h2>
                <div> 
                  <span>Always available support </span>
                </div>
                <div> 
                  <span>Instance assistance </span>
                </div>
                <div> 
                  <span>Comprehensive support</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon4} alt=""/>
                <h2 className="heading mt-4">Easy Online Booking</h2>
                <div> 
                  <span>User friendly interface</span>
                </div>
                <div> 
                  <span>Seamless process</span>
                </div>
                <div> 
                  <span>Instant Confirmation</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon5} alt=""/>
                <h2 className="heading mt-4">Parcel Tracking</h2>
                <div> 
                  <span>We deliver all items with
care and never co-load,
damage. You can trust us to
minimising likelihood of
move time-sensitive and
delicate goods</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon6} alt=""/>
                <h2 className="heading mt-4">Nationwide Coverage</h2>
                <div> 
                  <span>Extensive reach</span>
                </div>
                <div> 
                  <span>Comprehensive Network</span>
                </div>
                <div> 
                  <span>Reliable delivery anywhere</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon7} alt=""/>
                <h2 className="heading mt-4">Dedicated Drivers</h2>
                <div> 
                  <span>Your goods from A to B without
Our dedicated couriers move.
We provide dedicated delivery
ever co-loading.
that’s safe, secure and efficient</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon8} alt=""/>
                <h2 className="heading mt-4">Exclusive Pricing</h2>
                <div> 
                  <span>To the customer, determined by
Each of our quotes are tailored
journey length, consignment
requirements such as
size and type of vehicle required</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon9} alt=""/>
                <h2 className="heading mt-4">Reliable & Trusted</h2>
                <div> 
                  <span>Paramount importance to us,
Customer satisfaction is of
Trustpilot rating. We deliver
as shown by our 5-star
on time, every time</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon10} alt=""/>
                <h2 className="heading mt-4">Fast & Efficient Deliveries</h2>
                <div> 
                  <span>Hour and deliver same day.
We can collect within the
Hassle-free and economical
logistics</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon11} alt=""/>
                <h2 className="heading mt-4">Collection 30 - 60 minutes</h2>
                <div> 
                  <span>With drivers network throughout the UK, we aim to collect within 60 minutes with guaranteed sameday delivery.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>	

    <section className="ftco-section">
      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
          	{/* <span className="subheading">Industry</span> */}
            <h2>Industries</h2>
          </div>
        </div>
        </div>
        <div className='container'>
        <div className="row d-flex theindus">
        <IndustryComponent/>
        </div>
      </div>
    </section>

        <section className="ftco-section contact-section">
          <div className="container">
            <div className="row d-flex mb-5 contact-info">
              <div className="col-md-12">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <div className="w-100 rounded mb-2 text-center">
                      <h1> Get in Touch</h1>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="w-100 rounded mb-2 text-center">
                      <p>
                        <a href="tel://1234567920">01218158330</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="w-100 rounded mb-2 text-center">
                      <p>
                        <a
                          href="mailto:sales@parcellodge.com"
                          className="green-text"
                        >
                          sales@parcellodge.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-flex mb-5 contact-info bg-green">
              <ContactForm />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Service;
