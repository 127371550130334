
import React, { Component } from 'react';
import { vApis } from '../Apis';

class Cart extends Component {

    bookCartItem= async () => {
        const { cart } = this.props;
        console.log("car in props============",cart)
        var data = cart.postcodes;
        console.log("data============",data)
        const convertDataToObject = (data) => {
            const postcodes = data.split(',').map(item => item.trim());
        
            const result = postcodes.map(postcode => {
              if (postcode.startsWith('c_')) {
                return { postcode: postcode.substring(2), stoptype: 'collection' };
              } else if (postcode.startsWith('d_')) {
                return { postcode: postcode.substring(2), stoptype: 'delivery' };
              } else {
                return null;
              }
            }).filter(item => item !== null); // Filter out any null values
        
            return result;
          };
        
          // Convert the input data
          const convertedData = convertDataToObject(data);

          // Stringify the result
          const stringifiedData = JSON.stringify(convertedData);
        console.log("stringifiedData===",stringifiedData)
          // Save to localStorage
          localStorage.setItem('postcodestest', stringifiedData);

        localStorage.setItem("collectionpostcodes", cart.collection.join(', '));
        localStorage.setItem("deliverypostcodes", cart.delivery.join(', '));
        localStorage.setItem("quoteestimation", cart.quote);
        localStorage.setItem("vat", cart.VAT);
        localStorage.setItem('extras', JSON.stringify(cart.Extras));
        localStorage.setItem('vehicledistancesurcharge', cart.vehicle_distance_surcharge);
        localStorage.setItem("distance", cart.distance);
        localStorage.setItem("cartid", cart.cart_id);
        window.location.href="/booking"
    }

    deleteCartItem = async () => {
        const { cart, fetchCarts } = this.props;
        let obj = JSON.stringify({
            cart_id:  cart.cart_id
        })
       let delCartResp = await vApis.deleteData('payments/cart/',obj);
       console.log("delCartResp==",delCartResp);
if(delCartResp?.data?.status){
       fetchCarts();
}
    }
  render() {
    const { cart } = this.props;
    const keysToDisplay = {
        vehicle: 'Vehicle',
        delivery: 'Delivery',
        collection: 'Collection',
        quote: 'Quote'
      };
    return (
      
        
        // <tr>
                   
        //             <td>
        //               <div className="d-flex align-items-center">
        //                 <span className="mr-2">{cart.cart_id}</span>
        //                </div> 
        //             </td>
        //             <td>
        //               <div className="d-flex align-items-center">
        //                 <span className="mr-2">{cart.collection.join(', ')}</span>
        //                </div> 
        //             </td>
        //             <td>
        //               <div className="d-flex align-items-center">
        //                 <span className="mr-2">{cart.delivery.join(', ')}</span>
        //                </div> 
        //             </td>
                    
        //             <td>
        //               <div className="d-flex align-items-center">
        //                 <span className="mr-2">{cart.vehicle}</span>
        //                </div> 
        //             </td>
        //             <td>
        //               <span className="d-flex align-items-center">

        //               <span className="mr-2">{cart.quote}</span>
        //               </span>
        //             </td>
        //             <td>
        //               <span className="d-flex align-items-center">

        //               <span className="mr-2" onClick={this.deleteCartItem}>Delete</span>
        //               </span>
        //             </td>
        //             {/* <td>
        //               <div className="avatar-group">
        //                 {order.vehicle}
        //               </div>
        //             </td> */}
                    
                  
        //           </tr>
                 
        <div className="card rounded-4 shadow p-1 mb-3 bg-white">
        <div className="card-body p-4">
          <div className="row d-flex justify-content-between align-items-center cart-details">
          {Object.entries(keysToDisplay).map(([key, displayName]) => (
              cart[key] !== undefined && (
                <div key={key} className="col-md-2 col-lg-2 col-xl-2 text-center">
                  <p className="lead fw-normal mb-2">{displayName}</p>
                  <h5 className="mb-0">{Array.isArray(cart[key]) ? cart[key].join(', ') : cart[key]}</h5>
                </div>
              )
            ))}
            

            <div className="col-md-2 col-lg-2 col-xl-2 text-center">
                <button  type="button" className="btn btn-secondary py-2 px-3" onClick={this.bookCartItem}>Book Now</button>
            </div>

            <div className="col-md-1 col-lg-1 col-xl-1 text-center">
              <a href="#!" className="text-danger del-cart" onClick={this.deleteCartItem}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="red" d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
              </a>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Cart;
