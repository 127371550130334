import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";

import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";

import calendaricon from "./assets/images/calendar_icon.png";
import diffobj from "./assets/images/diffobj.png";


import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";

import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import  MapContainer  from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import industryImg from "./assets/images/image_6.jpg";
import IndustryBanner from './assets/images/service-page.jpg';

class Services extends Component {
    constructor(props) {
      super(props);
      this.state = {
        con_first_name: "",
        serviceList: [],
      }
    }

    componentDidMount(){
        this.fetchServices()
    }
    fetchServices = async () => {
        console.log("fetchServices=====")
          const allServices = await vApis.fetchData('calculator/services/');
          console.log("all allServices====",allServices?.data?.services_list);
          // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
          const serviceList = allServices?.data?.services_list;
          console.log("serviceList===",serviceList);
          this.setState({ serviceList })
      };


    render() {

  return (
    <div id="services">
        <Header/>

        <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${IndustryBanner})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Services <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Services</h1>
          </div>
        </div>
      </div>
    </section>

        <section className="ftco-about">
			<div className="container">
				<div className="row no-gutters">
					
					<div className="col-md-12 wrap-about ftco-animate">
	          <div className="md-5">
	          	{/* <span className="subheading">About us</span> */}
	            <h2 className="mb-4 green-text">Why Choose Parcel Lodge?</h2>
              </div>
              <div id='welcome-text' className='col-md-12'>
	            <p>Choose Parcel Lodge for reliability and versatility in all your courier needs. Whether it’s urgent same-day deliveries, economical next-day options, expansive international shipping, or our comprehensive fulfillment and storage services, we ensure your parcels reach their destination securely and on time. Our commitment to excellence and customer satisfaction makes us the preferred choice for individuals and businesses alike. Experience the convenience and peace of mind with every shipment you entrust to us.</p>
              {/* <p><a href="#" className="btn btn-primary py-3 px-4">Search Vehicle</a></p> */}
              </div>
					</div>
					
					
					
				</div>
			</div>
		</section>

        <section className="ftco-section">
			<div className="container-fluid">
				<div className="row justify-content-center mb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-3">Our Services</h2>
          </div>
        </div>
				<div className="row justify-content-center">
				{/* <ServiceComponent serviceName="Same Day Delivery" serviceimg={time} />
        <ServiceComponent serviceName="Next Day Delivery" serviceimg={van} />
        <ServiceComponent serviceName="International Delivery" serviceimg={aero} />
        <ServiceComponent serviceName="Fulfilment" serviceimg={box} />
        <ServiceComponent serviceName="Storage" serviceimg={storage} /> */}
       
        {this.state.serviceList?.map(service => (
          
          <ServiceComponent
         
            key={service.id}
            id={service.id}
            serviceName={service.Service}
            serviceImg={service.icon}
          />
        ))}
				</div>
			</div>
		</section>	



<Footer/>


    </div>
  );
    }
}

export default Services;
