import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class MapContainer extends Component {
  render() {
    const mapStyles = {
      width: '100%',
      height: '100%',
      position: 'static'
    };

    return (
      <div id={"test"} style={{ height: '517px', width: '100%', float:'left' }}>
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{ lat: 51.5074, lng: -0.1278 }}
      >
        <Marker position={{ lat: 51.5074, lng: -0.1278 }} />
      </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDspRkVC11ROLjdr2WqHVAIlY7mR4S_g90'
})(MapContainer);
