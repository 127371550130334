import React from 'react';
import homeWelcomeImg from "./assets/images/about.jpg";
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import Header from './Header';
import Footer from './Footer';

import aboutBGImage from './assets/images/Aboutus.jpg';
import IndustryComponent from './IndustriesSection';
function About() {
  return (
    <div id='about-page'>

<Header/>
<section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${aboutBGImage})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>About <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">About</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-about">
    <div className="container">
      <div className="row no-gutters">
      <div id="service-section-main"><div id="top-service-section"><span id="service-top-title">Tailored Courier Services for Construction</span><p id="service-top-description">In the construction industry, the timely and safe delivery of materials is crucial. Our specialized courier services cater to the construction sector by ensuring that building materials, tools, and equipment are delivered precisely when and where they are needed. Our fleet is equipped to handle everything from small tools to large construction materials, ensuring they arrive at the construction site on time.</p></div></div>
        <div className="col-md-8 wrap-about ftco-animate">
          <div className="md-5">
            {/* <span className="subheading">About us</span> */}
            <h2 className="mb-4 green-text">Why Choose Parcel Lodge?</h2>
            </div>
            <div id='welcome-text' className='col-md-11'>
            <p>Choose Parcel Lodge for reliability and versatility in all your courier needs. Whether it’s urgent same-day deliveries, economical next-day options, expansive international shipping, or our comprehensive fulfillment and storage services, we ensure your parcels reach their destination securely and on time. Our commitment to excellence and customer satisfaction makes us the preferred choice for individuals and businesses alike. Experience the convenience and peace of mind with every shipment you entrust to us.</p>
            {/* <p><a href="#" className="btn btn-primary py-3 px-4">Search Vehicle</a></p> */}
            </div>
        </div>
        
        <div className="col-md-4 p-md-5 img img-2 d-flex justify-content-center align-items-center" style={{
      backgroundImage: `url(${homeWelcomeImg})`
    }}>
        </div>
        
      </div>
    </div>
  </section>

  <section className="ftco-section">
      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
          	{/* <span className="subheading">Industry</span> */}
            <h2>Industries</h2>
          </div>
        </div>
        </div>
        <div className='container'>
        <div className="row d-flex">
        <IndustryComponent/>
        </div>
      </div>
    </section>
  <Footer/>
  </div>
  );
}

export default About;
