import React, { Component } from 'react';
import Order from './Order'; // Import the Order component
import '../assets/animate.css';
import '../assets/open-iconic-bootstrap.min.css';
import '../assets/animate.css';
import '../assets/owl.carousel.min.css';
import '../assets/owl.theme.default.min.css';
import '../assets/magnific-popup.css';
import '../assets/aos.css';
import '../assets/ionicons.min.css';
import '../assets/bootstrap-datepicker.css';
import '../assets/jquery.timepicker.css';
import '../assets/flaticon.css';
import '../assets/icomoon.css';
import '../assets/style.css';
import Header from '../Header';
import Footer from '../Footer';
import { vApis } from '../Apis';
import OrderParcelManagement from './OrderParcelManagement';

class OrderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      activeTab: 'sameDayOrders'
    };
  }

  componentDidMount() {
    // Simulate fetching orders from an API or local storage
    this.fetchOrders();
  }

  fetchOrders = async () => {
    // Example: Fetch orders from an API endpoint
    // Replace with your actual API call or local data retrieval
    // const orders = [
    //   { id: 1, date: '2024-07-08', totalAmount: 150.25, status: "pending", vehicle: "Van"  },
    //   { id: 2, date: '2024-07-07', totalAmount: 99.99, status: "complete", vehicle: "Long Vehicle" },
      
    // ];

   
      //console.log("fetchVehicles=====")
      let orderemail = {
        email: localStorage.getItem("bookingemail")

      }
      console.log("order email obj==",orderemail)
        const allOrders = await vApis.postData('payments/orders/',JSON.stringify(orderemail));
        console.log("all orders===",allOrders)
        //console.log("all vehicles====",allVehicles?.data?.vehicles_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const orderRes = allOrders?.data?.orders_list;
        console.log("orderRes===",orderRes);
    
    this.setState({ orders: orderRes });
  };

  handleTabChange = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  render() {
    const { orders, activeTab } = this.state;

    return (
      <div className="page-container">
            <Header />
            <div className="order-management container">
          <div className="main-content">
            <div className="container mt-7">
              <h2 className="mb-3">All Orders</h2>
              
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'sameDayOrders' ? 'active' : ''}`}
                    onClick={() => this.handleTabChange('sameDayOrders')}
                  >
                    Same Day Orders
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeTab === 'nextDayOrders' ? 'active' : ''}`}
                    onClick={() => this.handleTabChange('nextDayOrders')}
                  >
                    Next Day Orders
                  </a>
                </li>
              </ul>
              
              <div className="tab-content">
                {activeTab === 'sameDayOrders' && (
                  <div className="tab-pane fade show active">
                    <div className="row">
                      <div className="col">
                        <div className="card shadow">
                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Order</th>
                                  <th scope="col">Vehicle</th>
                                  <th scope="col">Distance</th>
                                  <th scope="col">Cost</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order) => (
                                  <Order key={order.id} order={order} />
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {activeTab === 'nextDayOrders' && (
                  <div className="tab-pane fade show active">
                    {/* Here you can render the Next Day Orders component */}
                    <OrderParcelManagement/>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      <Footer />
      </div>
    );
  }
}

export default OrderManagement;
