import logo from './logo.svg';
import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import ContactBanner from "./assets/images/ContactUs.jpeg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from './Header';
import Footer from './Footer';
import {vApis} from './Apis';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactForm from './ContactForm';

//function App() {
  class Contact extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // con_first_name: "",
        // con_last_name: "",
        // con_email: "",
        // con_phone: "",
        // con_inquiry: "",
        // submitted_msg: "",
        // con_loader: false
      }
    }

    
    render() {
    return (
        <div id="contact-page">
          {this.state.con_loader && 
          <div className="loader-overlay">
          <CircularProgress className="loader" />
          </div>
          }
            <Header/>
                
    <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${ContactBanner})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Contact <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Contact Us</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="ftco-section contact-section">
      <div className="container">
        <div className="row d-flex mb-5 contact-info">
        	<div className="col-md-12">
        		<div className="row mb-5">
		          <div className="col-md-12">
              <div className=" w-100 p-2  rounded mb-2 text-center">
			          	
			            <p>We’d love to hear what you think!</p>
                  <p>Please fill out the form with any questions you might have.</p>
			          </div>
              
              
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <h1> Get in Touch</h1>
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <p><a href="tel://1234567920">01218158330</a></p>
                  {/* <p><a href="tel://1234567920">+44 333 2244 839</a></p> */}
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className=" w-100 rounded mb-2 text-center">
			          
			            <p> <a href="mailto:sales@parcellodge.com" className='green-text'>sales@parcellodge.com</a></p>
			          </div>
		          </div>
		        </div>
          </div>
          
        </div>

        <div className="row d-flex mb-5 contact-info bg-green">
        
        <ContactForm/>
        
        </div>
        <div className="row justify-content-center">
        	<div className="col-md-12">
        		{/* <div id="map" className="bg-white"></div> */}
        	</div>
        </div>
      </div>
    </section>
	

            <Footer/>
        </div>
    )
    }
}
export default Contact;