
import React, { Component } from 'react';


class OrderParcel extends Component {
  render() {
    const { order } = this.props;
console.log("orderrrrrrrrrrrrrrrrrrr=",order)
    return (
      
        
        <tr>
                   
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Order ID"]}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Name"]}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Email"]}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Dimensions"]}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Weight"]}</span>
                       </div> 
                    </td>
                    <td>
                    £{order["price"]}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order["Tracking Number"]}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2"><a href='{order["Tracking URL"]'>Check Tracking</a></span>
                       </div> 
                    </td>
                    {/* <td>
                      <div className="avatar-group">
                        {order.vehicle}
                      </div>
                    </td> */}
                    
                  
                  </tr>
                 
      
    );
  }
}

export default OrderParcel;
