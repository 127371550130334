import React from 'react';
import homeWelcomeImg from "./assets/images/about.png";
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import Header from './Header';
import Footer from './Footer';

import aboutBGImage from './assets/images/about-banner.png';
import IndustryComponent from './IndustriesSection';
import { useParams } from 'react-router-dom';

const City = () => {
  const { cityName } = useParams();
  
  return (
    <div id="city-page">
        

<Header/>
      
     
      <section class="ftco-about">
      <div class="container mt-5">
        <div class="row">
            <div class="col-12 text-center city-content">
            
      <h2> Same Day Courier {cityName}</h2>
      
      <p> We offer same day courier service in {cityName} as well, we have transport offices in cities throughout the UK.</p>
                <p>If you need to book same day courier delivery in {cityName} , our team will find the right size vehicle for you, no matter what size your package is, from important documents, parcels, pallets, etc to fragile items and construction equipment we can cover it all. </p>
                <p>Our friendly experienced drivers are the best in the business delivering a wide range of items and are fully insured.</p>

                <p>Parcel Lodge understands that parcels and packages come in all shapes and sizes, and understands that the need for protective, efficient parcel and item delivery is growing all the time. In a day and age where people expect to receive items quicker than ever before, we are proud to offer a lightning-quick same day courier service to get your essentials on the road sooner.</p>

                <p>We have over 12,000 drivers across the country so you can rest assured we can get to you in less than 1 hour and get your items delivered anywhere in the country today. When you need something picked up and delivered ASAP you can trust Parcel Lodge.</p>

                <p>If you would like an immediate quote or more information on {cityName} same day courier service, you can call us on 01218158330 or you can email us at sales@parcellodge.com. Alternatively you can complete the quote form.</p>
                 </div>
        </div>
        {/* <div class="row mt-4">
            <div class="col-12">
                <h1>Comprehensive Vehicle Services</h1>
                <p>Our expert team offers a wide range of vehicle services including maintenance, repairs, and inspections. Whether you need a routine oil change, brake service, engine diagnostics, or emergency repairs, we’ve got you covered. Our service centers are equipped with the latest technology and staffed by experienced technicians who are committed to delivering exceptional service and ensuring your vehicle operates at its best. We are dedicated to providing reliable and efficient services to keep you safely on the road, no matter where you are.</p>
            </div>
        </div> */}
    </div>
      </section>
    
    <Footer/>
    </div>
  );
};

export default City;
