import React, { Component } from 'react';
import Cart from './Cart';
import '../assets/animate.css';
import '../assets/open-iconic-bootstrap.min.css';
import '../assets/animate.css';
import '../assets/owl.carousel.min.css';
import '../assets/owl.theme.default.min.css';
import '../assets/magnific-popup.css';
import '../assets/aos.css';
import '../assets/ionicons.min.css';
import '../assets/bootstrap-datepicker.css';
import '../assets/jquery.timepicker.css';
import '../assets/flaticon.css';
import '../assets/icomoon.css';
import '../assets/style.css';
import Header from '../Header';
import Footer from '../Footer';
import { vApis } from '../Apis';

class CartManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
        carts: []
    };
  }

  componentDidMount() {
    // Simulate fetching orders from an API or local storage
    this.fetchCarts();
  }

  fetchCarts = async () => {
    const allOrders = await vApis.fetchData('payments/cart/');
        console.log("all carts===",allOrders)
        const orderRes = allOrders?.data?.cart;
        console.log("orderRes===",orderRes.length);
    //localStorage.setItem('carcounter',orderRes.length)
    if(orderRes.length>0){
      localStorage.setItem("cartcounter",orderRes.length)
    }
    else if(orderRes.length===0) {
      localStorage.removeItem("cartcounter")
    }
    this.setState({ carts: orderRes });
  };

  render() {
    const { carts } = this.state;

    return (
      <div className="page-container">
            <Header />
      <div className="order-management container">


      <section className="h-100">
  <div className="container h-100 py-5">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12">

        <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Cart</h2>
          
        </div>

        {carts.map(cart => (
            <Cart key={cart.id} cart={cart} fetchCarts={this.fetchCarts}/>
          ))}
        {/* <div className="card">
          <div className="card-body">
            <button  type="button" className="btn btn-secondary py-3 px-4 float-right">Proceed to Booking</button>
          </div>
        </div> */}

      </div>
    </div>
  </div>
</section>
















 
        
      </div>
      <Footer />
      </div>
    );
  }
}

export default CartManagement;
