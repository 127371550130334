import React, { Component } from 'react';
import { vApis } from './Apis';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      con_first_name: "",
      con_last_name: "",
      con_email: "",
      con_phone: "",
      con_inquiry: "",
      submitted_msg: "",
      con_loader: false,
      errors: {}
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateForm = () => {
    const errors = {};
    const { con_first_name, con_email, con_phone } = this.state;

    if (!con_first_name) errors.con_first_name = "Name is required";
    if (!con_email) {
      errors.con_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(con_email)) {
      errors.con_email = "Email address is invalid";
    }
    if (!con_phone) errors.con_phone = "Phone number is required";

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  submitContact = async (e) => {
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    this.setState({ con_loader: true });
    const con_data = JSON.stringify({
      first_name: this.state.con_first_name,
      last_name: this.state.con_last_name,
      email: this.state.con_email,
      phone: this.state.con_phone,
      inquiry: this.state.con_inquiry
    });

    try {
      const response = await vApis.postData('users/contact/', con_data);
      this.setState({
        con_first_name: "",
        con_last_name: "",
        con_email: "",
        con_phone: "",
        con_inquiry: "",
        submitted_msg: response?.data?.message,
        con_loader: false
      });
    } catch (error) {
      this.setState({
        submitted_msg: "An error occurred. Please try again.",
        con_loader: false
      });
    }
  };

  render() {
    const { con_first_name, con_last_name, con_email, con_phone, con_inquiry, submitted_msg, errors } = this.state;

    return (
      <div className="col-md-12">
        <form onSubmit={this.submitContact} className="contact-form">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${errors.con_first_name ? 'is-invalid' : ''}`}
                  placeholder="Your Name *"
                  name="con_first_name"
                  value={con_first_name}
                  onChange={this.handleChange}
                />
                {errors.con_first_name && <div className="invalid-feedback">{errors.con_first_name}</div>}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${errors.con_email ? 'is-invalid' : ''}`}
                  placeholder="Your Email *"
                  name="con_email"
                  value={con_email}
                  onChange={this.handleChange}
                />
                {errors.con_email && <div className="invalid-feedback">{errors.con_email}</div>}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${errors.con_phone ? 'is-invalid' : ''}`}
                  placeholder="Phone *"
                  name="con_phone"
                  value={con_phone}
                  onChange={this.handleChange}
                />
                {errors.con_phone && <div className="invalid-feedback">{errors.con_phone}</div>}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="con_last_name"
                  value={con_last_name}
                  onChange={this.handleChange}
                />
              </div>
              
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <textarea
                  name="con_inquiry"
                  cols="30"
                  rows="7"
                  className="form-control"
                  placeholder="Message"
                  value={con_inquiry}
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  value="Send Message"
                  className="btn btn-primary py-3 px-5"
                />
              </div>
            </div>
          </div>
        </form>
        {submitted_msg && <p className='form_submitted'>{submitted_msg}</p>}
      </div>
    );
  }
}

export default ContactForm;
