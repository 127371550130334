import logo from './logo.svg';
import React, { Component } from 'react';
import './assets/owl.carousel.min.css';
// import './assets/animate.css';
// import './assets/open-iconic-bootstrap.min.css';
// import './assets/animate.css';
// import './assets/owl.carousel.min.css';
// import './assets/owl.theme.default.min.css';
// import './assets/magnific-popup.css';
// import './assets/aos.css';
// import './assets/ionicons.min.css';

// import './assets/bootstrap-datepicker.css';
// import './assets/jquery.timepicker.css';
// import './assets/flaticon.css';
// import './assets/icomoon.css';
import './assets/style.css';
import ContactBanner from "./assets/images/contact-banner.png";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from './Header';
import Footer from './Footer';
import {vApis} from './Apis';
import CircularProgress from '@material-ui/core/CircularProgress';  
import smartObj1 from './assets/images/Vector Smart Object.png';
import vanImg from './assets/images/car-1.png';
// import 'bootstrap/dist/css/bootstrap.min.css';
//import { Carousel } from 'react-bootstrap';
import {Carousel} from '3d-react-carousal';

import CustomCarousel from './Carousel';
import aboutBGImage from './assets/images/Vehicles.jpg';
import $ from 'jquery';

//function App() {
  class Vehicles extends Component {

    selectedSlide=0

    constructor(props) {
        super(props);
        this.state = {
          currentIndex: 0,
          vehicles: [],
          index: 0,
          
            maxData: 3,
            i: 2,
            intervalId: null,
            selectedItem: null,
            selectedSlideIndex: 0,
            refreshState:0
        //     slides : [
        //       <img  src="https://picsum.photos/800/300/?random" alt="1" />,
        //     <img  src="https://picsum.photos/800/301/?random" alt="2" />  ,
        //     <img  src="https://picsum.photos/800/302/?random" alt="3" />  , 
        //     <img  src="https://picsum.photos/800/303/?random" alt="4" />  ,
        //     <img src="https://picsum.photos/800/304/?random" alt="5" />  ]
         };
      }
    
      handleSlideChange = (index) => {
        console.log("******",index)
        //this.selectedSlide=index
        // console.log("Slide changed to index:", index);
          this.setState((prevState) => ({ selectedSlideIndex: index }));  
        //this.setState((prevState) => ({ refreshState: new Date()?.getSeconds() }));       
      

         //this.setState({ selectedSlideIndex: index });
    };

    // callback = () => {
    //     console.log("callback", this.state.selectedSlideIndex);
    // };

      // handleNext = () => {
      //   const { vehicles } = this.props;
      //   const { index } = this.state;
      //   const nextIndex = index === vehicles.length - 1 ? 0 : index + 1;
      //   this.setState({ index: nextIndex });
      // };
    
      // handlePrev = () => {
      //   const { vehicles } = this.props;
      //   const { index } = this.state;
      //   const prevIndex = index === 0 ? vehicles.length - 1 : index - 1;
      //   this.setState({ index: prevIndex });
      // };
    
      componentDidMount() {
        // Fetch data from API and update state
        this.fetchVehicles();
        
       
      }
    
      // rotateCircle = () => {
      //   const { i } = this.state;
      //   document.querySelector('.dotCircle').style.transform = 'rotate(' + (360 - (i - 1) * 36) + 'deg)';
      //   document.querySelector('.dotCircle').style.transition = '2s';
      //   document.querySelectorAll('.itemDot').forEach(function (item) {
      //     item.style.transform = 'rotate(' + ((i - 1) * 36) + 'deg)';
      //     item.style.transition = '1s';
      //   });
      // }
    
      fetchVehicles = async () => {
        //console.log("fetchVehicles=====")
          const allVehicles = await vApis.fetchData('calculator/vehicles/');
          //console.log("all vehicles====",allVehicles?.data?.vehicles_list);
          // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
          const vehicles = allVehicles?.data?.vehicles_list;
          //console.log("vehicles===",vehicles);
          this.setState({ vehicles});

          //this.loadAnimation()
      };
    

      
 
  //  handleVanSelect = (van) => {
  //       this.setState({ selectedItem: van.id });
  //   }

  //   handleCarouselSelect = (selectedIndex) => {
  //       this.setState({ currentIndex: selectedIndex });
  //   }
    // callback = (index) => {
    //   console.log("callback==========",index);
    //   this.setState({ selectedSlideIndex: index });
    // }

    getSlides=()=>{
      if(this.state.vehicles?.length>0){
       let dt= this.state.vehicles[this.state.selectedSlideIndex]
       console.log(dt)
      }

      return []
    }
    render() {

        
      //const { vehicles } = this.state;
       const selectedVan = this.state.vehicles[this.state.selectedSlideIndex];
        
 console.log("selected van-3===...........>>>>>>>>>--",selectedVan);


// if (this.state.vehicles.length === 0) {
//   return <div>Loading...</div>;
// }
    return (
        
        <div id="vehicles-page">
         
         
            <Header/>
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
                backgroundImage: `url(${aboutBGImage})`
              }} data-stellar-background-ratio="0.5">
              <div className="overlay"></div>
              <div className="container">
                <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
                  <div className="col-md-9 ftco-animate pb-5">
                    <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Vehicles <i className="ion-ios-arrow-forward"></i></span></p>
                    <h1 className="mb-3 bread">Vehicles</h1>
                  </div>
                </div>
              </div>
            </section>

            <section className="hero-wrap ftco-section">
{/* <div id="bg1">
</div>
<div id="bg2">
</div> */}

            <div className="container">

            <h1 className='vehic-gui'>VEHICLE GUIDANCE </h1>
<p className='main-desc'>Compare, find, and select the right vehicle for your delivery requirement.</p>
               <div className="row align-items-center">
               
                  
                  <div className="col-md-12 car the-main-text">
                  <CustomCarousel vehicles={this.state.vehicles} onSlideChange={(e)=>this.handleSlideChange(e)} />
                  {/* <Carousel 
                  slides={this.state.vehicles?.map(slide => (
                      <img src={slide.image} alt={slide.vehicle_type} />
                  ))}
                  selectedSlideIndex={this.selectedSlide} // Selects the first slide programmatically
                  autoplay={true} interval={6000} arrows={true}
                   onSlideChange={this.handleSlideChange}/> */}
                     
                  </div>
                  
               </div>

               <div className="row align-items-center">
    <div className="col-md-12 mtop">
        <h2>{selectedVan ? selectedVan.vehicle_type : 'N/A'}</h2>
        <span dangerouslySetInnerHTML={{ __html: selectedVan ? selectedVan.description : 'N/A' }} />
    </div>
</div>

            </div>
       

	   </section>
	         
		<section className=" ftco-car-details ftco-section">

      <div className='container'>
      	<div className="row justify-content-center">
          <div className='col-md-9'>
          <div className="row props-cont">
      		<div className="col-md d-flex align-self-stretch ftco-animate greyBG">
                    <div className="media block-6 services col-md-12">
                        <div className="media-body py-md-2">
                            <div className="d-flex mb-3 align-items-center">
                                <div className="text m-auto">
                                    <h3 className="heading mb-0">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src={smartObj1} alt=""/>
                                        </div>
                                        <span>Length</span>
                                        <span className='veh-props-val'>{selectedVan ? selectedVan.length : 'N/A'}m</span>

                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          <div className="col-md d-flex align-self-stretch ftco-animate greyBGL">
            <div className="media block-6 services col-md-12">
              <div className="media-body py-md-2">
              	<div className="d-flex mb-3 align-items-center">
	              	
	              	<div className="text m-auto">
		                <h3 className="heading mb-0">
                    <div className="icon d-flex align-items-center justify-content-center">
                    {/* <span className="flaticon-dashboard"></span> */}
                    <img src={smartObj1} alt=""/>
                    </div>
		                	<span>Height</span>
                      <span className='veh-props-val'>{selectedVan ? selectedVan.height : 'N/A'}m</span>
		                </h3>
	                </div>
                </div>
              </div>
            </div>      
          </div>
          <div className="col-md d-flex align-self-stretch ftco-animate greyBG">
            <div className="media block-6 services col-md-12">
              <div className="media-body py-md-2">
              	<div className="d-flex mb-3 align-items-center">
	              	
	              	<div className="text m-auto">
		                <h3 className="heading mb-0">
                    <div className="icon d-flex align-items-center justify-content-center">
                    <img src={smartObj1} alt=""/>
                    </div>
		                	<span>Width</span>
                      <span className='veh-props-val'>{selectedVan ? selectedVan.width : 'N/A'}m</span>
		                </h3>
	                </div>
                </div>
              </div>
            </div>      
          </div>
          <div className="col-md d-flex align-self-stretch ftco-animate greyBGL">
            <div className="media block-6 services col-md-12">
              <div className="media-body py-md-2">
              	<div className="d-flex mb-3 align-items-center">
	              	
	              	<div className="text m-auto">
		                <h3 className="heading mb-0">
                    <div className="icon d-flex align-items-center justify-content-center">
                    <img src={smartObj1} alt=""/>
                    </div>
		                	<span>Weight</span>
                      <span className='veh-props-val'>{selectedVan ? selectedVan.weight : 'N/A'}kg</span>
		                </h3>
	                </div>
                </div>
              </div>
            </div>      
          </div>
          <div className="col-md d-flex align-self-stretch ftco-animate greyBG">
            <div className="media block-6 services col-md-12">
              <div className="media-body py-md-2">
              	<div className="d-flex mb-3 align-items-center">
	              	
	              	<div className="text m-auto">
		                <h3 className="heading mb-0">
                    <div className="icon d-flex align-items-center justify-content-center">
                    <img src={smartObj1} alt=""/>
                    </div>
		                	<span>Pallets</span>
                      <span className='veh-props-val'>{selectedVan ? selectedVan.pallets : 'N/A'}</span>
		                </h3>
	                </div>
                </div>
              </div>
            </div>      
          </div>
          </div>
          </div>
      	</div>

      </div>
    </section>


            <Footer/>
        </div>
    )
    }
}
// Carousel.NextArrow = () => (
//   <div className="custom-carousel-arrow next-arrow">
//       <FontAwesomeIcon icon={faArrowRight} />
//   </div>
// );

// Carousel.PrevArrow = () => (
//   <div className="custom-carousel-arrow prev-arrow">
//       <FontAwesomeIcon icon={faArrowLeft} />
//   </div>
// );
export default Vehicles;