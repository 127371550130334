import React, { Component } from 'react';
import {vApis} from './Apis';
class IndustryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      industriesList: []
    };
  }

  
componentDidMount(){
    this.fetchIndustries()
}
fetchIndustries = async () => {
    //console.log("fetchIndustries=====")
      const allIndus = await vApis.fetchData('calculator/industries/');
     // console.log("all indus====",allIndus?.data?.industries_list);
      // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
      const industriesList = allIndus?.data?.industries_list;
      //console.log("industriesList===",industriesList);
      this.setState({ industriesList })
  };

  render() {
    
    // const { industryName, industryDesc } = this.props;
    // const { industryImg } = this.props;
    // const { id } = this.props;
    // console.log("industryImg=====",industryImg)
    return (
        <>
        {this.state.industriesList?.map(indus => (
        <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">


        <div className="text pt-4 trust-sec text-center">
            <a href={`/industry/?id=${indus.id}`}>
              <img src={indus.image} alt=""/>
                <h2 className="heading mt-4">{indus.Industry}</h2>
                {/* {indus.description} */}
                </a>
              </div>
        </div>  
        </div>  
         ))}
         </>
    //   <div
    //     className="col-md-2"
    //     onMouseEnter={this.handleMouseEnter}
    //     onMouseLeave={this.handleMouseLeave}
    //   >
    //     <div className="services services-2 w-100 text-center">
    //       <div className="ser-img-cont d-flex align-items-center justify-content-center">
    //         {/* <span className="flaticon-wedding-car"></span> */}
    //         {console.log("serviceimg=====",serviceImg)}
    //         <img src={serviceImg} alt=""/>
    //       </div>
    //       <div className="text w-100">
    //         <h3 className="heading mb-2">{serviceName}</h3>
    //         {isHovered ? (
    //           <a href={`/service/?id=${id}`}>Read more</a>
    //         ) : (
    //           <p><FaArrowRight /></p>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    );
  }
}

export default IndustryComponent;
