import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";

import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";

import calendaricon from "./assets/images/calendar_icon.png";
import diffobj from "./assets/images/diffobj.png";


import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";

import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import  MapContainer  from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import industryImg from "./assets/images/image_6.jpg";
import IndustryBanner from './assets/images/contact-banner.png';
import ContactForm from './ContactForm';

class Industry extends Component {
    constructor(props) {
      super(props);
      this.state = {
        indusData: [],
      }
    }

   
    componentDidMount() {
        this.fetchIndustry()
      }

      fetchIndustry = async () => {
        console.log("fetchIndustry=====")
        const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
console.log("id here===",id)
    
          const Indus1 = await vApis.fetchData(`calculator/industries/${id}/`)
          console.log(" Indus1====",Indus1);
          // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
          const indusData = Indus1?.data?.Industry_data;
          console.log("indusData===",indusData);
          this.setState({ indusData })
      };
    render() {
        const { indusData } = this.state;
  return (
    <div id="service-page">
        <Header/>

        <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${indusData.image})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Industry <i className="ion-ios-arrow-forward"></i></span><span>{indusData.Industry}</span></p>
            <h1 className="mb-3 bread">{indusData.Industry}</h1>
          </div>
        </div>
      </div>
    </section>

        <section className="ftco-about">
			<div className="container">
				<div className="row no-gutters">
					
					<div className="col-md-12 wrap-about ftco-animate">
                    <div dangerouslySetInnerHTML={{ __html: indusData.description }} />
					</div>
					
					
					
				</div>
			</div>
		</section>


        <section className="ftco-section contact-section">
      <div className="container">
        <div className="row d-flex mb-5 contact-info">
        	<div className="col-md-12">
        		<div className="row mb-5">
		          <div className="col-md-12">
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <h1> Get in Touch</h1>
			          </div>
		          </div>
		          {/* <div className="col-md-12">
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <p><a href="tel://1234567920">+44 333 2244 839</a></p>
                  <p><a href="tel://1234567920">+44 333 2244 839</a></p>
			          </div>
		          </div> */}
		          <div className="col-md-12">
		          	<div className=" w-100 rounded mb-2 text-center">
			          
			            <p> <a href="mailto:sales@parcellodge.com" className='green-text'>sales@parcellodge.com</a></p>
			          </div>
		          </div>
		        </div>
          </div>
          
        </div>

        <div className="row d-flex mb-5 contact-info bg-green">
        
        <ContactForm/>
        
        </div>
        
      </div>
    </section>


<Footer/>


    </div>
  );
    }
}

export default Industry;
