import React, { Component } from 'react';
import OrderParcel from './OrderParcel'; // Import the Order component
import '../assets/animate.css';
import '../assets/open-iconic-bootstrap.min.css';
import '../assets/animate.css';
import '../assets/owl.carousel.min.css';
import '../assets/owl.theme.default.min.css';
import '../assets/magnific-popup.css';
import '../assets/aos.css';
import '../assets/ionicons.min.css';
import '../assets/bootstrap-datepicker.css';
import '../assets/jquery.timepicker.css';
import '../assets/flaticon.css';
import '../assets/icomoon.css';
import '../assets/style.css';
import { vApis } from '../Apis';

class OrderParcelManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  componentDidMount() {
    // Simulate fetching orders from an API or local storage
    this.fetchOrders();
  }

  fetchOrders = async () => {
    // Example: Fetch orders from an API endpoint
    // Replace with your actual API call or local data retrieval
    // const orders = [
    //   { id: 1, date: '2024-07-08', totalAmount: 150.25, status: "pending", vehicle: "Van"  },
    //   { id: 2, date: '2024-07-07', totalAmount: 99.99, status: "complete", vehicle: "Long Vehicle" },
      
    // ];

   
      //console.log("fetchVehicles=====")
      let orderemail = {
        email: localStorage.getItem("bookingemail")

      }
      console.log("order email obj==",orderemail)
        const allOrders = await vApis.postData('parcels/parcel-orders/',JSON.stringify(orderemail));
        console.log("all orders===",allOrders)
        //console.log("all vehicles====",allVehicles?.data?.vehicles_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const orderRes = allOrders?.data?.orders_list;
        console.log("orderRes===",orderRes);
    
    this.setState({ orders: orderRes });
  };

  render() {
    const { orders } = this.state;

    return (

	  <div className="row">

        <div className="col">
          <div className="card shadow">
            
            <div className="table-responsive">
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Order Id</th>
                    <th scope="col">Parcel Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Dimensions</th>
                    <th scope="col">Weight</th>
                    <th scope="col">Cost</th>
                    
                    <th scope="col">Tracking Number</th>
                    <th scope="col">Tracking Link</th>
                    
                    
                    
                  </tr>
                </thead>
                <tbody>
                
          {orders.map(order => (
            <OrderParcel key={order.id} order={order} />
          ))}
       
                 
			
				</tbody>
              </table>
            </div>
            {/* <div className="card-footer py-4">
              <nav aria-label="...">
                <ul className="pagination justify-content-end mb-0">
                  <li className="page-item disabled">
                    <a className="page-link" href="#" tabindex="-1">
                      <i className="fas fa-angle-left"></i>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">1</a>
                  </li>
                 
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-angle-right"></i>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </div>

  
    );
  }
}

export default OrderParcelManagement;
