
import React, { Component } from 'react';


class Order extends Component {
  render() {
    const { order } = this.props;

    return (
      
        
        <tr>
                   
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order.Order}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order.Vehicle}</span>
                       </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order.Distance}</span>
                       </div> 
                    </td>
                    <td>
                      ${order.Cost}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{order.Email}</span>
                       </div> 
                    </td>
                    <td>
                      <span className="badge badge-dot mr-4">

                        <i className={order.Status === 'InComplete' ? 'bg-warning' : 'bg-success'}></i> {order.Status}
                      </span>
                    </td>
                    {/* <td>
                      <div className="avatar-group">
                        {order.vehicle}
                      </div>
                    </td> */}
                    
                  
                  </tr>
                 
      
    );
  }
}

export default Order;
