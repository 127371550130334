import React from 'react';
import homeWelcomeImg from "./assets/images/about.png";
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import Header from './Header';
import Footer from './Footer';

import aboutBGImage from './assets/images/about-banner.png';
import IndustryComponent from './IndustriesSection';
import { useParams } from 'react-router-dom';

const CookiePolicy = () => {
  
  
  return (
    <div id="terms-page">
        

<Header/>
      {/* <h1>{cityName}</h1> */}
  
      <section className="ftco-about mt-5">
        

    
  <div className="container mt-5 termsp">
    <h1 className="text-center">Parcel Lodge Ltd Privacy & Cookie Policy</h1>
    <h2>Contact Details</h2>
    <p>
      Post: Weston Street, Walsall, West Midlands, WS1 4EJ, GB <br></br>
      Telephone: 01218158330 <br></br>
      Email: sales@parcellodge.com
    </p>

    <h2>What Information We Collect, Use, and Why</h2>
    <p>We collect or use the following information to provide services and goods, including delivery:</p>
    <ul>
      <li>Names and contact details</li>
      <li>Addresses</li>
      <li>Purchase or account history</li>
      <li>Payment details (including card or bank information for transfers and direct debits)</li>
      <li>Account information</li>
      <li>Website user information (including user journeys and cookie tracking)</li>
      <li>Information relating to compliments or complaints</li>
    </ul>
    <p>We collect or use the following information for the operation of customer accounts and guarantees:</p>
    <ul>
      <li>Names and contact details</li>
      <li>Addresses</li>
      <li>Payment details (including card or bank information for transfers and direct debits)</li>
      <li>Purchase history</li>
      <li>Account information, including registration details</li>
      <li>Information used for security purposes</li>
      <li>Marketing preferences</li>
    </ul>
    <p>We collect or use the following information to prevent crime, prosecute offenders, or defend against legal action:</p>
    <ul>
      <li>Names and contact information</li>
      <li>Customer or client accounts and records</li>
      <li>Criminal offence data (including Disclosure Barring Service (DBS), Access NI or Disclosure Scotland checks)</li>
      <li>Video and CCTV recordings of public areas (including indoor and outdoor spaces)</li>
      <li>Video and CCTV recordings of private or staff only areas</li>
      <li>Dashcam footage - outside vehicle – without audio</li>
      <li>Dashcam footage – outside vehicle – with audio</li>
      <li>Dashcam footage - inside vehicle - without audio</li>
      <li>Call recordings</li>
      <li>Financial transaction information</li>
      <li>Information relating to health and safety</li>
    </ul>
    <p>We collect or use the following information for service updates or marketing purposes:</p>
    <ul>
      <li>Names and contact details</li>
      <li>Marketing preferences</li>
      <li>Location data</li>
      <li>Call recordings</li>
      <li>IP addresses</li>
      <li>Website and app user journey information</li>
      <li>Records of consent, where appropriate</li>
    </ul>
    <p>We collect or use the following information to comply with legal requirements:</p>
    <ul>
      <li>Identification documents</li>
      <li>Financial transaction information</li>
      <li>Criminal offence data (including Disclosure Barring Service (DBS), Access NI or Disclosure Scotland checks)</li>
      <li>Health and safety information</li>
    </ul>
    <p>We collect or use the following information for recruitment purposes:</p>
    <ul>
      <li>Contact details (e.g. name, address, telephone number or personal email address)</li>
      <li>Date of birth</li>
      <li>National Insurance number</li>
      <li>Copies of passports or other photo ID</li>
      <li>Employment history (e.g. job application, employment references or secondary employment)</li>
      <li>Education history (e.g. qualifications)</li>
      <li>Right to work information</li>
      <li>Details of any criminal convictions (e.g. Disclosure Barring Service (DBS), Access NI or Disclosure Scotland checks)</li>
      <li>Security clearance details (e.g. basic checks and higher security clearance)</li>
      <li>Biometric information (where used to identify someone)</li>
    </ul>

    <h2>Lawful Bases</h2>
    <p>
      Our lawful bases for collecting or using personal information to provide services and goods are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>
    <p>
      Our lawful bases for collecting or using personal information for the operation of customer accounts and guarantees are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>
    <p>
      Our lawful bases for collecting or using personal information to prevent crime, prosecute offenders or defend against legal action are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>
    <p>
      Our lawful bases for collecting or using personal information for service updates or marketing purposes are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>
    <p>
      Our lawful bases for collecting or using personal information for legal requirements are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>
    <p>
      Our lawful bases for collecting or using personal information for recruitment purposes are:
      <ul>
        <li>Consent</li>
        <li>Contract</li>
      </ul>
    </p>

    <h2>Where We Get Personal Information From</h2>
    <ul>
      <li>People directly</li>
      <li>Debt collection agencies</li>
    </ul>

    <h2>How Long We Keep Information</h2>
    <p>
      We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. 
      To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirement.
      By law we have to keep basic information about our customers (including contact, identity, financial and transaction data) for six years after they cease being customers for tax purposes.
      In some circumstances you can ask us to delete your data.
    </p>

    <h2>Who We Share Information With</h2>
    <p>Data processors:</p>
    <ul>
      <li>Internal & External parties as set out in the Glossary</li>
    </ul>
    <p>
      This data processor does the following activities for us: Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Data and Privacy Policy. We require all third parties to the security of your personal data and to treat in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
    </p>
    <p>Other organisations:</p>
    <ul>
      <li>Financial or fraud investigation authorities</li>
      <li>Debt collection agencies</li>
    </ul>

    <h2>Sharing Information Outside the UK</h2>
    <p>
      Where necessary, we may transfer personal information outside of the UK. When doing so, we comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.
      Where necessary, our data processors may share personal information outside of the UK. When doing so, they comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.
    </p>

    <h2>Cookie Policy</h2>
    <p>
      A cookie is a small file, stored on a user’s hard drive by a website. Its purpose is to collect data relating to the user’s browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.
    </p>
    <p>We use the following cookies on our site:</p>
    <ul>
	      <li><strong>Functional Cookies:</strong> Used to remember the selections you make on our site so that your selections are saved for your next visits.</li>
      <li><strong>Analytical Cookies:</strong> Allow us to improve the design and functionality of our site by collecting data on how you access our site, e.g. data on the content you access, how long you stay on our site, etc.</li>
      <li><strong>Targeting Cookies:</strong> Collect data on how you use the site and your preferences. This allows us to personalize the information you see on our site for you.</li>
      <li><strong>Third-Party Cookies:</strong> Created by a website other than ours.</li>
    </ul>

    <h2>Your Data Protection Rights</h2>
    <p>Under data protection law, you have rights including:</p>
    <ul>
      <li><strong>Your right of access:</strong> You have the right to ask us for copies of your personal data.</li>
      <li><strong>Your right to rectification:</strong> You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
      <li><strong>Your right to erasure:</strong> You have the right to ask us to erase your personal data in certain circumstances.</li>
      <li><strong>Your right to restriction of processing:</strong> You have the right to ask us to restrict the processing of your personal data in certain circumstances.</li>
      <li><strong>Your right to object to processing:</strong> You have the right to object to the processing of your personal data in certain circumstances.</li>
      <li><strong>Your right to data portability:</strong> You have the right to ask that we transfer the personal data you gave us to another organization, or to you, in certain circumstances.</li>
      <li><strong>Your right to withdraw consent:</strong> When we use consent as our lawful basis you have the right to withdraw your consent.</li>
    </ul>
    <p>You don’t usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you. To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.</p>

    <h2>How to Complain</h2>
    <p>
      If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.
      If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.
    </p>
    <p>
      The ICO’s address:<br></br>
      Information Commissioner’s Office<br></br>
      Wycliffe House<br></br>
      Water Lane<br></br>
      Wilmslow<br></br>
      Cheshire<br></br>
      SK9 5AF<br></br>
      Helpline number: 0303 123 1113<br></br>
      Website: <a href="https://www.ico.org.uk/make-a-complaint" target="_blank">https://www.ico.org.uk/make-a-complaint</a>
    </p>
  </div>


         
      </section>
    
    <Footer/>
    </div>
  );
};

export default CookiePolicy;
