// UnderConstruction.js
import React from 'react';

const UnderConstruction = () => (
  <div id="underconstruction" style={{ textAlign: 'center', padding: '50px' }}>
    <h1>Site Under Construction</h1>
    <p>We are working hard to bring you the best experience. Please check back later.</p>
  </div>
);

export default UnderConstruction;
