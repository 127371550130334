import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import stripePromise from './stripe';
import Header from './Header';
import Footer from './Footer';

const App = () => {
  return (
    <div>
      <Header/>
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
      <Footer/>
    </div>
  );
};

export default App;
