
class Apis {
  constructor(props) {
    //super(props);
    this.state = {
      //apisBaseUrl: "http://127.0.0.1:8000/"
      apisBaseUrl: "https://www.parcellodge.com/api/"
    }
  }
 getCookie=(name)=> {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }

  fetchService = async (url) => {
    //console.log("fetch data method",url)
    const API_KEY = '28ff7958-68d5-4804-bdaa-092c54f1c902';
      const API_SECRET = 'e9cc07bcb1cb4d3080390adf73b0f731';
  
      let username = "Kashif.uk123@gmail.com";
      let pass = "Gravitywork7"
      const headers = new Headers({
        'Authorization': 'Basic ' + btoa(`${API_KEY}:${API_SECRET}`),
      });
      let test = 'Basic ' + btoa(`${username}:${pass}`)
      console.log("headers====",headers);

      console.log("headers1====",test)
      try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      } catch (error) {
        //console.error('Error fetching data:', error);
        return null; // Return null or handle the error as needed
      }
    };
   
  
    postService= async (endpoint,body) => {
      console.log("endpoint===",endpoint);
      console.log("body===",body)
      const API_KEY = '28ff7958-68d5-4804-bdaa-092c54f1c902';
      const API_SECRET = 'e9cc07bcb1cb4d3080390adf73b0f731';
  
      const headers = new Headers({
        'Authorization': 'Basic ' + btoa(`${API_KEY}:${API_SECRET}`),
      });
      try {
        const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(`${API_KEY}:${API_SECRET}`),
        },
        body: body,
      
      });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log("check resp==============",response)
        return response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
        return null; // Return null or handle the error as needed
      }
    }

    fetchData = async (url) => {
      try {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const headers = {
          'Content-Type': 'application/json',
        };
    
        if (username) {
          headers['Authorization'] = `Token ${token}`;
        }
    
        const response = await fetch(this.state.apisBaseUrl + url, {
          method: 'GET',
          headers: headers,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
        return null; // Return null or handle the error as needed
      }
    };
    
      postData = async (endpoint, body) => {
        try {
          console.log("endpoint==============================", endpoint);
          let response;
          const token = localStorage.getItem('token');
          const username = localStorage.getItem('username');
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.getCookie('csrftoken')
          };
      
          if (endpoint !== "users/login/"  && username) {
            console.log("not login api");
            headers['Authorization'] = `Token ${token}`;
          }
      
          response = await fetch(this.state.apisBaseUrl + endpoint, {
            method: 'POST',
            headers: headers,
            body: body,
          });
      
          console.log("check response=================", response);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        } catch (error) {
          console.error('Error fetching data:', error);
          return null; // Return null or handle the error as needed
        }
      }
      
      deleteData = async (endpoint, body) => {
        try {
          console.log("endpoint==============================", endpoint);
          let response;
          const token = localStorage.getItem('token');
          const username = localStorage.getItem('username');
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.getCookie('csrftoken')
          };
      
          if (endpoint !== "users/login/"  && username) {
            console.log("not login api");
            headers['Authorization'] = `Token ${token}`;
          }
      
          response = await fetch(this.state.apisBaseUrl + endpoint, {
            method: 'DELETE',
            headers: headers,
            body: body,
          });
      
          console.log("check response=================", response);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        } catch (error) {
          console.error('Error fetching data:', error);
          return null; // Return null or handle the error as needed
        }
      }
    
}
var vApis = new Apis();
export  {vApis};