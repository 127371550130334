import logo from './logo.svg';
import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import './assets/booking.css';
import sidebarBGImage from "./assets/images/bookingbanner.png";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import './assets/toast.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//function App() {
  class Booking extends Component {
    constructor() {
      super();
   
      const currentUKTime = new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/London', hour12: false }).split(':');
      this.state = {
      selectedDate: new Date(),
      deliveryDate: new Date(),
       estimated_quote: 0,
       vat_val:0,
       return_journey:0,
       total_price: 0,
       theVehicle: "",
       theVehicleId: 0,
       vehicleImg: "",
       length: "",
       width: "",
       height: "",
       weight: "",
       pallets: "",
       description: "",
       first_name: "",
       last_name: "",
       company: "",
       email: localStorage.getItem("bookingemail") ? localStorage.getItem("bookingemail") : "",
       telephone_number: "",
       collection_company: "",
       collection_first_name: "",
       collection_last_name: "",
       collection_contact_telephone: "",
       collection_address_line_1: "",
       collection_address_line_2: "",
       collection_city: "",
       collection_postcode: "",
       delivery_company: "",
       delivery_first_name: "",
       delivery_last_name: "",
       delivery_contact_telephone: "",
       delivery_address_line_1: "",
       delivery_address_line_2: "",
       delivery_city: "",
       delivery_postcode: "",
       postcode:[],
       isLoading: false,
       notes:"",
       selectedHour: currentUKTime[0].padStart(2, '0'), // Current selected hour
      selectedMinute: currentUKTime[1].padStart(2, '0'), // Current selected minute

      selectedHour2: currentUKTime[0].padStart(2, '0'), // Current selected hour
      selectedMinute2: currentUKTime[1].padStart(2, '0'), // Current selected minute

      selectedDeliveryHour: currentUKTime[0].padStart(2, '0'), // Current selected hour
      selectedDeliveryMinute: currentUKTime[1].padStart(2, '0'), // Current selected minute

      selectedDeliveryHour2: currentUKTime[0].padStart(2, '0'), // Current selected hour
      selectedDeliveryMinute2: currentUKTime[1].padStart(2, '0'), // Current selected minute
        out_of_hours: "",
        distance: 0,
        collectionOption: "between",
        deliveryOption: "asap",
        extras: {},
        minMileCost: "",
        quote_id: 0,
        vehicle_distance_surcharge: 0,
        stops: [],
        stoptype: []
      };
      this.handleBookFormInputChange = this.handleBookFormInputChange.bind(this);
    }
    componentDidMount(){
     
      //const extrasFromStorage = localStorage.getItem("extras") ? JSON.parse(localStorage.getItem("extras")) : ""
      //const extrasFromStorage = localStorage.getItem("extras") ? JSON.parse(localStorage.getItem("extras")) ?? "" : "";
      const extras = localStorage.getItem("extras");

let parsedExtras;
if (extras && extras !== "undefined") {
    try {
        parsedExtras = JSON.parse(extras);
    } catch (e) {
        console.error("Error parsing JSON:", e);
        parsedExtras = "";
    }
} else {
    parsedExtras = "";
}

        if (parsedExtras) {
          this.setState({ extras: parsedExtras });
        }
      this.setState({
        estimated_quote: localStorage.getItem("quoteestimation") ? parseFloat(localStorage.getItem("quoteestimation")) : 0,
        collection_postcode: localStorage.getItem("collectionpostcodes") ? localStorage.getItem("collectionpostcodes") : "",
        delivery_postcode: localStorage.getItem("deliverypostcodes") ? localStorage.getItem("deliverypostcodes") : "",
        theVehicleId: localStorage.getItem("selectedvehicle") ? localStorage.getItem("selectedvehicle") : "",
        vat_val: localStorage.getItem("vat") ? parseFloat(localStorage.getItem("vat")) : 0,
        //out_of_hours: localStorage.getItem("outofhours") ? localStorage.getItem("outofhours") : "",
        distance: localStorage.getItem("distance") ? parseFloat(localStorage.getItem("distance")) : 0,
        quote_id: localStorage.getItem("quoteid") ? parseFloat(localStorage.getItem("quoteid")) : 0,

        vehicle_distance_surcharge: localStorage.getItem("vehicledistancesurcharge") ? parseFloat(localStorage.getItem("vehicledistancesurcharge")) : 0,
        //vehicle_distance_surcharge: localStorage.getItem('vehicledistancesurcharge') ? localStorage.getItem("vehicledistancesurcharge") :""
      }, () => {
        // This callback function will be executed after the state is set
        this.fetchVehicle();
        const total = parseFloat(localStorage.getItem("quoteestimation")) + parseFloat(localStorage.getItem("vat")); // Calculate total price
          console.log("total=====",total)
          this.setState({total_price: total  });
          localStorage.setItem("totalamount",total)
        
        //this.notifySuccess()
      });
console.log("collection_postcodes====",this.state.collection_postcode)
console.log("postcodestest====",localStorage.getItem("postcodestest"))
let stopsDatainJson = localStorage.getItem("postcodestest");
console.log("stopsDatainJson===",stopsDatainJson)
console.log("stopsDatainJson1===",JSON.parse(stopsDatainJson))
      const stops = JSON.parse(stopsDatainJson).map((stop, index) => ({
        stopid: index,
        postcode:stop.postcode,
        stoptype: stop.stoptype,
        //delivery_postcode: localStorage.getItem("deliverypostcodes").split(', ')[index],
        selectedDate: new Date(),
      collectionOption: 'between',
      selectedHour: '00',
      selectedMinute: '00',
      selectedHour2: '00',
      selectedMinute2: '00',
      deliveryDate: new Date(),
      deliveryOption: 'between',
      selectedDeliveryHour: '00',
      selectedDeliveryMinute: '00',
      selectedDeliveryHour2: '00',
      selectedDeliveryMinute2: '00',
      //quote_id: null, // Assuming this will be set dynamically
      }));
      console.log("check stops1====",stops)
      this.setState({stops:stops})
      console.log("stops1====",this.state.stops)
    }

    // handleHourChange = (event) => {
    //   this.setState({ selectedHour: event.target.value });
    //   this.getOutHours(event.target.value+":"+this.state.selectedMinute)
    // };
    // handleDeliveryHourChange = (event) => {
    //   this.setState({ selectedDeliveryHour: event.target.value });
    // };

    // handleHourChange2 = (event) => {
    //   this.setState({ selectedHour2: event.target.value });
    //   this.getOutHours(event.target.value+":"+this.state.selectedMinute2)
    // };
    // handleDeliveryHourChange2 = (event) => {
    //   this.setState({ selectedDeliveryHour2: event.target.value });
    // };

    // handleCollectionOptionChange = (event) => {
    //   this.setState({ collectionOption: event.target.value });
      
    // }
    // handleDeliveryOptionChange = (event) => {
    //   this.setState({ deliveryOption: event.target.value });
    // }
    // handleMinuteChange = (event) => {
    //   this.setState({ selectedMinute: event.target.value });
    //   this.getOutHours(this.state.selectedHour+":"+event.target.value)
    // };
    // handleDeliveryMinuteChange = (event) => {
    //   this.setState({ selectedDeliveryMinute: event.target.value });
    // };

    // handleMinuteChange2 = (event) => {
    //   this.setState({ selectedMinute2: event.target.value });
    //   this.getOutHours(this.state.selectedHour2+":"+event.target.value)
    // };
    // handleDeliveryMinuteChange2 = (event) => {
    //   this.setState({ selectedDeliveryMinute2: event.target.value });
    // };

    // getOutHoursonDateChange  = async (date,time) => {
    //   const currentDate = new Date();
    //   const options = { timeZone: 'Europe/London', hour12: false }; // Set the timezone to UK and 24-hour format
  
    //   // Format the time
    //   const formattedTime = currentDate.toLocaleTimeString('en-GB', options);
  
    //   // Get the current date
    //   const day = ('0' + currentDate.getDate()).slice(-2);
    //   const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    //   const year = currentDate.getFullYear();
    //   const formattedDate = `${day}/${month}/${year}`;
  
    //   // Create the JSON object
    //   const out_data = JSON.stringify({
    //     "time": time+":00",
    //     "date": date,
    //     "quote_id": this.state.quote_id
    //   });
    //   console.log("out_data===",out_data)
    //   const response = await vApis.postData('calculator/out_of_hours/',out_data);
      
    //   console.log("response of out of hours===",response?.data?.status);
    //   this.setState({out_of_hours:response?.data?.status})
    //   if(response?.data?.status ===true){
    //     this.setState({extras: response?.data?.Extras, out_of_hours: response?.data?.message, estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
    //     localStorage.setItem("quoteestimation",response?.data?.total)
    //   }
    //   else {
    //     //this.setState({  estimated_quote:response?.data?.total});
    //     this.setState({extras: response?.data?.Extras,  estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
    //     localStorage.setItem("quoteestimation",response?.data?.total)
  
    //     // const updatedPricingDetails = { ...this.state.extras };
    //     // delete updatedPricingDetails["Out of hours"];
    //     // this.setState({ extras: updatedPricingDetails });
    //   }
    // }
  
  
    // getOutHours = async (time) => {
    //   const currentDate = new Date();
    //   const options = { timeZone: 'Europe/London', hour12: false }; // Set the timezone to UK and 24-hour format
  
    //   // Format the time
    //   const formattedTime = currentDate.toLocaleTimeString('en-GB', options);
  
    //   // Get the current date
    //   const day = ('0' + currentDate.getDate()).slice(-2);
    //   const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    //   const year = currentDate.getFullYear();
    //   const formattedDate = `${day}/${month}/${year}`;
  
    //   // Create the JSON object
    //   const out_data = JSON.stringify({
    //     "time": time+":00",
    //     "date": formattedDate,
    //     "quote_id": this.state.quote_id
    //   });
    //   console.log("out_data===",out_data)
    //   const response = await vApis.postData('calculator/out_of_hours/',out_data);
      
    //   console.log("response of out of hours===",response?.data?.status);
    //   this.setState({out_of_hours:response?.data?.status})
    //   if(response?.data?.status ===true){
    //     this.setState({extras: response?.data?.Extras, out_of_hours: response?.data?.message, estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
    //     localStorage.setItem("quoteestimation",response?.data?.total)
    //   }
    //   else {
    //     //this.setState({  estimated_quote:response?.data?.total});
    //     this.setState({extras: response?.data?.Extras, estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
    //     localStorage.setItem("quoteestimation",response?.data?.total)
  
    //     // const updatedPricingDetails = { ...this.state.extras };
    //     // delete updatedPricingDetails["Out of hours"];
    //     // this.setState({ extras: updatedPricingDetails });
    //   }
    // }
    // handleDateChange = (date) => {
    //   console.log("data change===",date);
    //   const day = ('0' + date.getDate()).slice(-2);
    //     const month = ('0' + (date.getMonth() + 1)).slice(-2);
    //     const year = date.getFullYear();
    //     const formattedDateUp = `${day}/${month}/${year}`;
        
    //     this.setState({ selectedDate: date });
  
    //     this.getOutHoursonDateChange(formattedDateUp,this.state.selectedHour+":"+this.state.selectedMinute)
    // };
    // handleDeliveryDateChange = (date) => {
    //   this.setState({ deliveryDate: date });
    // };

    handleDateChange = (date, stopIndex) => {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
  
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedDate = date;
        return { stops: updatedStops };
      }, () => {
        //this.getOutHoursonDateChange(formattedDate, this.state.stops[stopIndex].selectedHour + ":" + this.state.stops[stopIndex].selectedMinute, stopIndex);
      });
    };
  
    handleDeliveryDateChange = (date, stopIndex) => {
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].deliveryDate = date;
        return { stops: updatedStops };
      });
    };
  
    handleHourChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedHour = value;
        return { stops: updatedStops };
      }, () => {
        //this.getOutHours(this.state.stops[stopIndex].selectedHour + ":" + this.state.stops[stopIndex].selectedMinute, stopIndex);
      });
    };
  
    handleDeliveryHourChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedDeliveryHour = value;
        return { stops: updatedStops };
      });
    };
  
    handleHourChange2 = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedHour2 = value;
        return { stops: updatedStops };
      }, () => {
        //this.getOutHours(this.state.stops[stopIndex].selectedHour2 + ":" + this.state.stops[stopIndex].selectedMinute2, stopIndex);
      });
    };
  
    handleDeliveryHourChange2 = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedDeliveryHour2 = value;
        return { stops: updatedStops };
      });
    };
  
    handleMinuteChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedMinute = value;
        return { stops: updatedStops };
      }, () => {
        //this.getOutHours(this.state.stops[stopIndex].selectedHour + ":" + this.state.stops[stopIndex].selectedMinute, stopIndex);
      });
    };
  
    handleDeliveryMinuteChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedDeliveryMinute = value;
        return { stops: updatedStops };
      });
    };
  
    handleMinuteChange2 = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].selectedMinute2 = value;
        return { stops: updatedStops };
      }, () => {
        //this.getOutHours(this.state.stops[stopIndex].selectedHour2 + ":" + this.state.stops[stopIndex].selectedMinute2, stopIndex);
      });
    };
  
    handleCollectionOptionChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].collectionOption = value;
        return { stops: updatedStops };
      });
    };
  
    handleDeliveryOptionChange = (event, stopIndex) => {
      const value = event.target.value;
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        updatedStops[stopIndex].deliveryOption = value;
        return { stops: updatedStops };
      });
    };
  
    getOutHoursonDateChange = async (date, time, stopIndex) => {
      const out_data = JSON.stringify({
        "time": time + ":00",
        "date": date,
        //"quote_id": this.state.stops[stopIndex].quote_id,
        "quote_id": this.state.quote_id
      });
      console.log("out_data===", out_data);
      const response = await vApis.postData('calculator/out_of_hours/', out_data);
  
      console.log("response of out of hours===", response?.data?.status);
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        if (response?.data?.status === true) {
          updatedStops[stopIndex] = {
            ...updatedStops[stopIndex],
            extras: response?.data?.Extras,
            out_of_hours: response?.data?.message,
            estimated_quote: response?.data?.total,
            vat_val: response?.data?.VAT,
            return_journey: response?.data?.return_journey
          };
          this.setState({extras: response?.data?.Extras, out_of_hours: response?.data?.message, estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
        } else {
          updatedStops[stopIndex] = {
            ...updatedStops[stopIndex],
            extras: response?.data?.Extras,
            estimated_quote: response?.data?.total,
            vat_val: response?.data?.VAT,
            return_journey: response?.data?.return_journey
          };
          this.setState({extras: response?.data?.Extras,  estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
        }
        return { stops: updatedStops };
      });
      localStorage.setItem("quoteestimation", response?.data?.total);
    };
  
    getOutHours = async (time, stopIndex) => {
      const currentDate = new Date();
      const options = { timeZone: 'Europe/London', hour12: false };
      const day = ('0' + currentDate.getDate()).slice(-2);
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const year = currentDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
  
      const out_data = JSON.stringify({
        "time": time + ":00",
        "date": formattedDate,
        //"quote_id": this.state.stops[stopIndex].quote_id,
        "quote_id": this.state.quote_id
      });
      console.log("out_data===", out_data);
      const response = await vApis.postData('calculator/out_of_hours/', out_data);
  
      console.log("response of out of hours===", response?.data?.status);
      this.setState(prevState => {
        const updatedStops = [...prevState.stops];
        if (response?.data?.status === true) {
          updatedStops[stopIndex] = {
            ...updatedStops[stopIndex],
            extras: response?.data?.Extras,
            out_of_hours: response?.data?.message,
            estimated_quote: response?.data?.total,
            vat_val: response?.data?.VAT,
            return_journey: response?.data?.return_journey
          };
          this.setState({extras: response?.data?.Extras, out_of_hours: response?.data?.message, estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
        } else {
          updatedStops[stopIndex] = {
            ...updatedStops[stopIndex],
            extras: response?.data?.Extras,
            estimated_quote: response?.data?.total,
            vat_val: response?.data?.VAT,
            return_journey: response?.data?.return_journey
          };
          this.setState({extras: response?.data?.Extras,  estimated_quote:response?.data?.total, vat_val:response?.data?.VAT, return_journey:response?.data?.return_journey });
        }
        return { stops: updatedStops };
      });
      localStorage.setItem("quoteestimation", response?.data?.total);
    };
    notifySuccess = () => {
      toast.success('Congratulations! Your vehicle booking has been successfully confirmed', {
        position: "top-right"
      });
    };



    fetchVehicle = async () => {
      
      let vehicleId = localStorage.getItem("selectedvehicle") ? localStorage.getItem("selectedvehicle") : "";
      
       //console.log("calling api")
        const responseVehicle = await vApis.fetchData('calculator/vehicles/'+vehicleId+"/");
        //console.log("getting api resp=======")
        //console.log("responseVehicle===",responseVehicle)
        const singleVehicle = responseVehicle.data;
        //console.log("singleVehicle=====",singleVehicle?.Vehicle_data)
        if(singleVehicle?.Vehicle_data!=="No Vehicle Found."){
          //console.log("selected vehicles ====", responseVehicle.data?.Vehicle_data.length);
          this.setState({
           theVehicle: singleVehicle.Vehicle_data.vehicle_type,
           length: singleVehicle.Vehicle_data.length,
           width: singleVehicle.Vehicle_data.width,
           height: singleVehicle.Vehicle_data.height,
           weight: singleVehicle.Vehicle_data.weight,
           vehicleImg: singleVehicle.Vehicle_data.image,
           minMileCost: singleVehicle.Vehicle_data.base_rate
         })
          
        }
         
    };

  handleBookFormInputChangeFields = (event) => {
    //console.log("handleBookFormInputChange===")
    const { name, value } = event.target;
    //console.log("name===",name);
    //console.log("value==",value)
    this.setState({ [name]: value });
  };

  handleBookFormSubmit = async (event) => {
    console.log("handleBookFormSubmit")
    event.preventDefault();
    this.setState({isLoading:true})
    // Here you can use this.state to access form values
    const {
      first_name,
      last_name,
      company,
      email,
      telephone_number,
      collection_company,
      collection_first_name,
      collection_last_name,
      collection_contact_telephone,
      collection_address_line_1,
      collection_address_line_2,
      collection_city,
      collection_postcode,
      delivery_company,
      delivery_first_name,
      delivery_last_name,
      delivery_contact_telephone,
      delivery_address_line_1,
      delivery_address_line_2,
      delivery_city,
      delivery_postcode,
      selectedDate,
      deliveryDate,
      selectedHour,
      selectedMinute,
      selectedHour2,
      selectedMinute2,
      selectedDeliveryHour,
      selectedDeliveryMinute,
      selectedDeliveryHour2,
      selectedDeliveryMinute2,
      collectionOption,
      deliveryOption,
      total_price,
      estimated_quote,
      distance,
      notes

    } = this.state;
    const tosChecked = document.getElementById('tos').checked;
    const prohibitedChecked = document.getElementById('tos1').checked;

    // Validation checks
    if (!first_name) {
        alert("First Name is required");
        this.setState({isLoading:false})
        return;
    }
    if (!last_name) {
        alert("Last Name is required");
        this.setState({isLoading:false})
        return;
    }
    if (!email) {
        alert("Email is required");
        this.setState({isLoading:false})
        return;
    }
    if (!tosChecked) {
        alert("You must accept the Terms and Conditions");
        this.setState({isLoading:false})
        return;
    }
    if (!prohibitedChecked) {
        alert("You must acknowledge the Prohibited Items");
        this.setState({isLoading:false})
        return;
    }
    const testformattedData = this.state.stops.map((stop, index) => stop);
    console.log("testformattedData==========",testformattedData)
    const formattedData = this.state.stops.map((stop, index) => ({
      
      stopid: index,
      stoptype: stop.stoptype,
      postalcode: stop.postcode,
      
      company: stop.collection_company,
      first_name: stop.collection_first_name,
      last_name: stop.collection_last_name,
      contact_telephone: stop.collection_contact_telephone,
      address_line_1: stop.collection_address_line_1,
      address_line_2: stop.collection_address_line_2,
      city: stop.collection_city,
      // delivery_company: stop.delivery_company,
      // delivery_first_name: stop.delivery_first_name,
      // delivery_last_name: stop.delivery_last_name,
      // delivery_contact_telephone: stop.delivery_contact_telephone,
      // delivery_address_line_1: stop.delivery_address_line_1,
      // delivery_address_line_2: stop.delivery_address_line_2,
      // delivery_city: stop.delivery_city,

      option: stop.collectionOption,


        start_time:`${stop.selectedHour}:${stop.selectedMinute}`,
        end_time:`${stop.selectedHour2}:${stop.selectedMinute2}`,
        date:stop.selectedDate,
      // deliveryDate: stop.deliveryDate,
      // deliveryOption: stop.deliveryOption,
      // selectedDeliveryHour: stop.selectedDeliveryHour,
      // selectedDeliveryMinute: stop.selectedDeliveryMinute,
      // selectedDeliveryHour2: stop.selectedDeliveryHour2,
      // selectedDeliveryMinute2: stop.selectedDeliveryMinute2,
    }));
    // Here you would send the `formattedData` to the API
    console.log("stop data on booking-----",formattedData);
    let dataBooking = JSON.stringify(
      {
        first_name: first_name,
        last_name: last_name,
        company: company,
        email: email,
        telephone_number: telephone_number,
        stops: formattedData,
        // collection_company: collection_company,
        // collection_first_name: collection_first_name,
        // collection_last_name: collection_last_name,
        // collection_contact_telephone: collection_contact_telephone,
        // collection_address_line_1: collection_address_line_1,
        // collection_address_line_2: collection_address_line_2,
        // collection_city: collection_city,
        // collection_postcode: collection_postcode,
        // delivery_company: delivery_company,
        // delivery_first_name: delivery_first_name,
        // delivery_last_name: delivery_last_name,
        // delivery_contact_telephone: delivery_contact_telephone,
        // delivery_address_line_1: delivery_address_line_1,
        // delivery_address_line_2: delivery_address_line_2,
        // delivery_city: delivery_city,
        // delivery_postcode: delivery_postcode,

        // collection_option:collectionOption,
        // collection_start_time:`${selectedHour}:${selectedMinute}`,
        // collection_end_time:`${selectedHour2}:${selectedMinute2}`,
        // collection_date:selectedDate,

        // delivery_date: deliveryDate,
        // delivery_option: deliveryOption,
       
        // delivery_start_time:  `${selectedDeliveryHour}:${selectedDeliveryMinute}`,
        // delivery_end_time:  `${selectedDeliveryHour2}:${selectedDeliveryMinute2}`,
        amount: estimated_quote,
        quote_id: this.state.quote_id,
        distance: distance,
        notes: notes,
        vehicle: this.state.theVehicleId
      }
      
    )



    console.log("dataBooking===",dataBooking);
    console.log("dataBooking json===",JSON.parse(dataBooking))
    let responseBookingForm;
    console.log("checking username on booking stage-------------------",localStorage.getItem("username"))
    if(localStorage.getItem("username")!="" && localStorage.getItem("username")!=null){
      responseBookingForm = await vApis.postData('booking/book_for_user/',dataBooking);
    }
    else{
      responseBookingForm = await vApis.postData('booking/book_for_guest/',dataBooking);
    }
console.log("responseBookingForm=======================",responseBookingForm)
    if(responseBookingForm?.data?.status===true){
      this.notifySuccess();
      this.setState({isLoading:false})
      localStorage.setItem("bookingid",responseBookingForm?.data?.booking_id);
      localStorage.setItem("bookingemail",responseBookingForm?.data?.email);
      localStorage.setItem("quoteestimation",responseBookingForm?.data?.total);
      localStorage.setItem("outofhoursmsg",responseBookingForm?.data?.msg);
      localStorage.setItem("outofhours",responseBookingForm?.data?.out_of_hour);
      localStorage.setItem("congestion",responseBookingForm?.data?.congestion);
      localStorage.setItem("sendcloud",false)
      window.location.href = '/pay';
    }
  };

 
  handleBookFormInputChange(event, stopIndex, type) {
    const { name, value } = event.target;
    this.setState(prevState => {
      const updatedStops = [...prevState.stops];
      updatedStops[stopIndex] = {
        ...updatedStops[stopIndex],
        [`${type}_${name}`]: value
      };
      return { stops: updatedStops };
    });
  }

  renderCollectionAddressSection(postcode, stopIndex) {
    console.log("stop data of forms===================",this.state.stops[stopIndex])
    return (
      <div className="w-full" key={`collection_${stopIndex}`}>
        {/* <h2 className="text-xl font-medium mb-3">{postcode.stoptype} Address of Stop {stopIndex + 1}</h2> */}
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Company</label>
          <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name="company" value={this.state.stops[stopIndex].collection_company || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
        <div className="sm:flex custom-row">
          <div className="flex flex-col sm:w-1/2 sm:pr-2">
            <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="first_name" value={this.state.stops[stopIndex].collection_first_name || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
          </div>
          <div className="flex flex-col sm:w-1/2 sm:pl-2">
            <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="last_name" value={this.state.stops[stopIndex].collection_last_name || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
          </div>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
          <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name="contact_telephone" value={this.state.stops[stopIndex].collection_contact_telephone || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="address_line_1" value={this.state.stops[stopIndex].collection_address_line_1 || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 2</label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="address_line_2" value={this.state.stops[stopIndex].collection_address_line_2 || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
          <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name="city" value={this.state.stops[stopIndex].collection_city || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Postcode</label>
          <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly disabled name="postcode" value={postcode.postcode} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'collection')}/>
        </div>
      </div>
    );
  }
  renderDeliveryAddressSection(postcode, stopIndex) {
    return (
      <div className="w-full" key={`delivery_${stopIndex}`}>
        <h2 className="text-xl font-medium mb-3">Delivery Address of Stop {stopIndex + 1}</h2>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Company</label>
          <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name="company" value={this.state.stops[stopIndex].delivery_company || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
        <div className="sm:flex custom-row">
          <div className="flex flex-col sm:w-1/2 sm:pr-2">
            <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="first_name" value={this.state.stops[stopIndex].delivery_first_name || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
          </div>
          <div className="flex flex-col sm:w-1/2 sm:pl-2">
            <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="last_name" value={this.state.stops[stopIndex].delivery_last_name || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
          </div>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
          <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name="contact_telephone" value={this.state.stops[stopIndex].delivery_contact_telephone || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="address_line_1" value={this.state.stops[stopIndex].delivery_address_line_1 || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 2</label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="address_line_2" value={this.state.stops[stopIndex].delivery_address_line_2 || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
          <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name="city" value={this.state.stops[stopIndex].delivery_city || ''} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Postcode</label>
          <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly disabled name="postcode" value={postcode} onChange={(e) => this.handleBookFormInputChange(e, stopIndex, 'delivery')}/>
        </div>
      </div>
    );
  }
  // renderAddressSection(postcode, index) {
  //   return (
  //     <div className="w-full" key={index}>
  //       <h2 className="text-xl font-medium mb-3">Collection Address of Stop {index + 1}</h2>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Company</label>
  //         <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name={`collection_company_${index}`} value={this.state[`collection_company_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="sm:flex custom-row">
  //         <div className="flex flex-col sm:w-1/2 sm:pr-2">
  //           <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
  //           <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name={`collection_first_name_${index}`} value={this.state[`collection_first_name_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //         </div>
  //         <div className="flex flex-col sm:w-1/2 sm:pl-2">
  //           <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
  //           <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name={`collection_last_name_${index}`} value={this.state[`collection_last_name_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //         </div>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
  //         <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name={`collection_contact_telephone_${index}`} value={this.state[`collection_contact_telephone_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
  //         <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name={`collection_address_line_1_${index}`} value={this.state[`collection_address_line_1_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Address line 2</label>
  //         <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name={`collection_address_line_2_${index}`} value={this.state[`collection_address_line_2_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
  //         <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name={`collection_city_${index}`} value={this.state[`collection_city_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Postcode</label>
  //         <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly disabled name={`collection_postcode_${index}`} value={postcode} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //     </div>
  //   );
  // }

  // renderDeliveryAddressSection(postcode,index) {
  //   return(
  //     <div className="w-full" key={index}>
  //       <h2 className="text-xl font-medium mb-3">Delivery Address of Stop {index + 1}</h2>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Company</label>
  //         <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name={`delivery_company_${index}`} value={this.state[`delivery_company_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="sm:flex custom-row">
  //         <div className="flex flex-col sm:w-1/2 sm:pr-2">
  //           <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span></label>
  //           <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name={`delivery_first_name_${index}`} value={this.state[`delivery_first_name_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //         </div>
  //         <div className="flex flex-col sm:w-1/2 sm:pl-2">
  //           <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span></label>
  //           <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name={`delivery_last_name_${index}`} value={this.state[`delivery_last_name_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //         </div>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span></label>
  //         <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name={`delivery_contact_telephone_${index}`} value={this.state[`delivery_contact_telephone_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span></label>
  //         <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name={`delivery_address_line_1_${index}`} value={this.state[`delivery_address_line_1_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Address line 2</label>
  //         <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name={`delivery_address_line_2_${index}`} value={this.state[`delivery_address_line_2_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">City <span className="text-red-500">*</span></label>
  //         <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name={`delivery_city_${index}`} value={this.state[`delivery_city_${index}`] || ''} onChange={this.handleBookFormInputChange}/>
  //       </div>
  //       <div className="flex flex-col custom-row">
  //         <label className="title font-light mb-0.5">Postcode</label>
  //         <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly="" disabled="" name={`delivery_postcode_${index}`} value={postcode} onChange={this.handleBookFormInputChange}/>
          
  //       </div>
  //     </div>
  //   )
  // }

  renderStopSection(stop, stopIndex) {
    console.log("stop====",stop);
    console.log("stop type====",stop.stoptype)
    return (
      <div key={stopIndex} className="stop-section mb-8">
       
        <div className='row mb-8 dateTimeCollect'>
          <div className='col-md-12 pr-4'>
            <div className='row'>
              <h2 className="text-xl font-medium mb-4 book-head">{stop.stoptype} Address of Stop {stopIndex + 1}:</h2>
              <div className='col-md-3 p-0'>
                <DatePicker
                  selected={stop.selectedDate}
                  onChange={(date) => this.handleDateChange(date, stopIndex)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className='timepicket-cont col-md-3'>
                <select className='bw' value={stop.collectionOption} onChange={(e) => this.handleCollectionOptionChange(e, stopIndex)}>
                  <option>between</option>
                  <option>at</option>
                  <option>before</option>
                  {stop.stoptype === "delivery" && <option>asap</option>}
                </select>
              </div>
              {stop.collectionOption !== "asap" && (
                <>
              <div className='timepicket-cont col-md-3'>
                <select className='timepicker' value={stop.selectedHour} onChange={(e) => this.handleHourChange(e, stopIndex)}>
                  {[...Array(24).keys()].map(hour => (
                    <option key={hour} value={hour < 10 ? `0${hour}` : `${hour}`}>{hour < 10 ? `0${hour}` : hour}</option>
                  ))}
                </select>
                :
                <select className='timepicker' value={stop.selectedMinute} onChange={(e) => this.handleMinuteChange(e, stopIndex)}>
                  {[...Array(60).keys()].map(minute => (
                    <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute < 10 ? `0${minute}` : minute}</option>
                  ))}
                </select>
              </div>
              {stop.collectionOption === "between" && (
                <div className='timepicket-cont col-md-3'>
                  <select className='timepicker' value={stop.selectedHour2} onChange={(e) => this.handleHourChange2(e, stopIndex)}>
                    {[...Array(24).keys()].map(hour => (
                      <option key={hour} value={hour < 10 ? `0${hour}` : `${hour}`}>{hour < 10 ? `0${hour}` : hour}</option>
                    ))}
                    </select>
              :
              <select className='timepicker' value={stop.selectedMinute2} onChange={(e) => this.handleMinuteChange2(e, stopIndex)}>
                {[...Array(60).keys()].map(minute => (
                  <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute < 10 ? `0${minute}` : minute}</option>
                ))}
              </select>
            </div>
          )}
          </>
          )}
        </div>
      </div>
      {/* <div className='col-md-6'>
        <div className='row'>
          <h2 className="text-xl font-medium mb-4 book-head">Delivery of Stop {stopIndex + 1}:</h2>
          <div className='col-md-3 p-0'>
            <DatePicker
              selected={stop.deliveryDate}
              onChange={(date) => this.handleDeliveryDateChange(date, stopIndex)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className='timepicket-cont col-md-3'>
            <select className='bw' value={stop.deliveryOption} onChange={(e) => this.handleDeliveryOptionChange(e, stopIndex)}>
              <option>between</option>
              <option>at</option>
              <option>by</option>
              <option>asap</option>
            </select>
          </div>
          {stop.deliveryOption !== "asap" && (
            <>
              <div className='timepicket-cont col-md-3'>
                <select className='timepicker' value={stop.selectedDeliveryHour} onChange={(e) => this.handleDeliveryHourChange(e, stopIndex)}>
                  {[...Array(24).keys()].map(hour => (
                    <option key={hour} value={hour < 10 ? `0${hour}` : `${hour}`}>{hour < 10 ? `0${hour}` : hour}</option>
                  ))}
                </select>
                :
                <select className='timepicker' value={stop.selectedDeliveryMinute} onChange={(e) => this.handleDeliveryMinuteChange(e, stopIndex)}>
                  {[...Array(60).keys()].map(minute => (
                    <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute < 10 ? `0${minute}` : minute}</option>
                  ))}
                </select>
              </div>
              {stop.deliveryOption === "between" && (
                <div className='timepicket-cont col-md-3'>
                  <select className='timepicker' value={stop.selectedDeliveryHour2} onChange={(e) => this.handleDeliveryHourChange2(e, stopIndex)}>
                    {[...Array(24).keys()].map(hour => (
                      <option key={hour} value={hour < 10 ? `0${hour}` : `${hour}`}>{hour < 10 ? `0${hour}` : hour}</option>
                    ))}
                  </select>
                  :
                  <select className='timepicker' value={stop.selectedDeliveryMinute2} onChange={(e) => this.handleDeliveryMinuteChange2(e, stopIndex)}>
                    {[...Array(60).keys()].map(minute => (
                      <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute < 10 ? `0${minute}` : minute}</option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
        </div>
      </div> */}
    </div>

    {this.renderCollectionAddressSection(stop, stopIndex)}
        {/* {this.renderDeliveryAddressSection(stop.delivery_postcode, stopIndex)} */}
  </div>
)
}
    render() {
      const { selectedHour, selectedMinute, selectedHour2, selectedMinute2, selectedDate, collectionOption, deliveryDate, deliveryOption, selectedDeliveryHour, selectedDeliveryMinute, selectedDeliveryHour2, selectedDeliveryMinute2 } = this.state;
      
      const postalCodes = this.state.collection_postcode.split(',');
      const deliveryPostalCodes = this.state.delivery_postcode.split(',');

      // const stops = postalCodes.map((postcode, index) => ({
      //   stopid: index,
      //   collection_postcode: postcode,
      //   delivery_postcode: deliveryPostalCodes[index]
      // }));
      //this.setState({stops})
      //const formattedTime = `${selectedHour}:${selectedMinute}`;
    return (
      
        <div id="booking">
          <Header/>
          <ToastContainer />
          {this.state.isLoading &&
     <div className="loader-overlay">
     <CircularProgress className="loader" />
     </div>
     }
          <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${sidebarBGImage})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Booking <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Booking</h1>
          </div>
        </div>
      </div>
    </section>

    <div className='row'>
            <div className="out_of_hours_msg">
                  {this.state.out_of_hours}
                </div>
            </div>

      
            <section className="custom-progress-2-container md:flex md:flex-row justify-between">
            
  <div className="left-side">
  {/* <div className="mt-4 mb-4">
        
        <h1 className="mt-4 mb-4 flex items-center">£{this.state.estimated_quote}<span className="text-sm font-light ml-3">(inc VAT)</span>
        </h1>
       <hr className="mt-5"/>
      </div> */}
    <h2 className="text-xl font-medium mb-4"> Your details: </h2>
    <form onSubmit={this.handleBookFormSubmit}>
    <div className="sm:flex custom-row">
    <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
      <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span>
      </label>
      <input type="text" maxLength="20" className="text-input rounded-md" name="first_name" value={this.state.first_name} onChange={this.handleBookFormInputChangeFields}/>
    </div>
    <div className="flex flex-col sm:w-1/2 sm:ml-3">
      <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span>
      </label>
      <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="last_name" value={this.state.last_name} onChange={this.handleBookFormInputChangeFields}/>
    </div>
  </div>
  <div className="custom-row">
    <div className="flex flex-col">
      <label className="title font-light mb-0.5">Company </label>
      <input type="text" maxLength="60" className="text-input rounded-md" autoComplete="off" name="company" value={this.state.company} onChange={this.handleBookFormInputChangeFields}/>
    </div>
  </div>
  <div className="sm:flex custom-row">
    <div className="flex flex-col w-full sm:w-1/2 sm:mr-3 mb-0 md:mb-0">
      <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span>
      </label>
      <input type="email" maxLength="255" className="text-input rounded-md" autoComplete="off" name="email" value={this.state.email} onChange={this.handleBookFormInputChangeFields}/>
    </div>
    <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
      <label className="title font-light mb-0.5">Telephone number <span className="text-red-500">*</span>
      </label>
      <input type="tel" maxLength="20" min="0" className="text-input rounded-md" autoComplete="off" name="telephone_number" value={this.state.telephone_number} onChange={this.handleBookFormInputChangeFields}/>
    </div>
  </div>
      <div className="custom-row">
        <div className="flex flex-col">
          <label className="title font-light mb-0.5">Notes</label>
          <input type="text" maxLength="65535" className="text-input rounded-md" name="notes" placeholder="Any additional details to help your delivery driver...." autoComplete="off" value={this.state.notes} onChange={this.handleBookFormInputChangeFields}/>
        </div>
      </div>
      {console.log("stops to be looped=====",this.state.stops)}
      {this.state.stops.map((stop, index) => this.renderStopSection(stop, index))}
      {/* {this.state.stops.map((stop, index) => (
          <div key={index}>
            {this.renderCollectionAddressSection(stop.collection_postcode, index)}
            {this.renderDeliveryAddressSection(stop.delivery_postcode, index)}
          </div>
        ))} */}
      {/* collection address area */}
      {/* <div>
        {postalCodes.map((postcode, index) => this.renderAddressSection(postcode, index))}
      </div>
      <div>
        {deliveryPostalCodes.map((postcode, index) => this.renderDeliveryAddressSection(postcode, index))}
      </div> */}
      {/* <div className="w-full">
        <h2 className="text-xl font-medium mb-3"> Collection Address</h2>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Company</label>
          <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name="collection_company" value={this.state.collection_company} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="sm:flex custom-row">
          <div className="flex flex-col sm:w-1/2 sm:pr-2">
            <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span>
            </label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="collection_first_name" value={this.state.collection_first_name} onChange={this.handleBookFormInputChange}/>
          </div>
          <div className="flex flex-col sm:w-1/2 sm:pl-2">
            <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span>
            </label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off"  name="collection_last_name" value={this.state.collection_last_name} onChange={this.handleBookFormInputChange}/>
          </div>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span>
          </label>
          <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name="collection_contact_telephone" value={this.state.collection_contact_telephone} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="collection_address_line_1" value={this.state.collection_address_line_1} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 2</label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="collection_address_line_2" value={this.state.collection_address_line_2} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name="collection_city" value={this.state.collection_city} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Postcode</label>
          <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly="" disabled="" name="collection_postcode" value={this.state.collection_postcode} onChange={this.handleBookFormInputChange}/>
          
        </div>
      </div> */}



      {/* delivery address area */}
      
      {/* <div className="w-full">
        <h2 className="text-xl font-medium mb-3"> Delivery Address</h2>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Company</label>
          <input type="text" maxLength="60" className="text-input rounded-md" placeholder="" autoComplete="off" name="delivery_company" value={this.state.delivery_company} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="sm:flex custom-row">
          <div className="flex flex-col sm:w-1/2 sm:pr-2">
            <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span>
            </label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="delivery_first_name" value={this.state.delivery_first_name} onChange={this.handleBookFormInputChange}/>
          </div>
          <div className="flex flex-col sm:w-1/2 sm:pl-2">
            <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span>
            </label>
            <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="delivery_last_name" value={this.state.delivery_last_name} onChange={this.handleBookFormInputChange}/>
          </div>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Contact Telephone <span className="text-red-500">*</span>
          </label>
          <input type="tel" maxLength="20" min="0" className="text-input rounded-md" placeholder="" autoComplete="off" name="delivery_contact_telephone" value={this.state.delivery_contact_telephone} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 1 <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="delivery_address_line_1" value={this.state.delivery_address_line_1} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Address line 2</label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="delivery_address_line_2" value={this.state.delivery_address_line_2} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name="delivery_city" value={this.state.delivery_city} onChange={this.handleBookFormInputChange}/>
        </div>
        <div className="flex flex-col custom-row">
          <label className="title font-light mb-0.5">Postcode</label>
          <input type="text" maxLength="10" className="text-input rounded-md input-bg-gray" placeholder="" readOnly="" disabled="" name="delivery_postcode" value={this.state.delivery_postcode} onChange={this.handleBookFormInputChange}/>
          
        </div>
      </div> */}

<div className="flex flex-col">
      <div>
        <input type="checkbox" className="mr-2" id="tos" />
        <label htmlFor="tos" id="tnc-label"> Non Alcohalic Items
          <span className="text-red-500"> *</span>
        </label>
      </div>

    </div>

      <div className="flex flex-col">
      <div>
        <input type="checkbox" className="mr-2" id="tos1" />
        <label htmlFor="tos1" id="tnc-label">I have read and accepted the <a href="/terms" target="_blank" className="text-green-primary text-base">T&amp;Cs</a>
          <span className="text-red-500"> *</span>
        </label>
      </div>

    </div>
    <div className="payment-box">
      
      <div className="mt-4 mb-4">
        
        <button className="custom-green-button default-bg-color" type='submit'>Book Now</button>
       
      </div>
    </div>
    </form>

  </div>
  <div className="right-side pt-2 mt-10 md:mt-0">
    <div>
    


      <div className="py-7 box mt-5 blueBg">
        {/* <h4 className="uppercase font-bold text-xl text-center mt-3">{this.state.theVehicle}</h4> */}
        <div className="px-6 pt-3 mt-2 pb-2">
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Distance</h5>
            <h5 className="text-md font-light custom-gray">{this.state.distance} miles</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Collection Postcodes</h5>
            <h5 className="text-md font-light custom-gray">{this.state.collection_postcode}</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Delivery Postcodes</h5>
            <h5 className="text-md font-light custom-gray">{this.state.delivery_postcode}</h5>
          </div>
         
        </div>
       
        
      </div>

      <div className=" py-7 box mb-8">
        <img src={this.state.vehicleImg} className="image" alt=""/>
        <h4 className="uppercase font-bold text-xl text-center mt-3">{this.state.theVehicle}</h4>
        <div className="px-6 pt-3 mt-2 pb-2 custom-border">
        {/* <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Min. Mile Cost</h5>
            <h5 className="text-md font-light custom-gray">{this.state.minMileCost}</h5>
          </div> */}
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Length</h5>
            <h5 className="text-md font-light custom-gray">{this.state.length}m</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Width</h5>
            <h5 className="text-md font-light custom-gray">{this.state.width}m</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Height</h5>
            <h5 className="text-md font-light custom-gray">{this.state.height}m</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Weight</h5>
            <h5 className="text-md font-light custom-gray">
              <div>{this.state.weight}kg</div>
            </h5>
          </div>
        </div>
        


        <div className="px-6 pt-3">
        {/* <h2>Extras:</h2> */}
      
          {/* Map through the key-value pairs of the "Extras" object */}
          {Object.entries(this.state.extras).map(([label, value]) => (
            <div className="flex justify-between py-0.5" key={label}>
              <h5 className="text-md font-light custom-gray">{label}</h5> <h5 className="text-md">£{parseFloat(value)}</h5>
            </div>
          ))}
       
      </div>
      <div className="px-6 pt-3">
      

      <div className="flex justify-between py-0.5">
            <h5 className="text-md font-light custom-gray">Vehicle Distance Surcharge:</h5>
            <h5 className="text-md">£{this.state.vehicle_distance_surcharge}</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-md font-bold">Total</h5>
            <h5 className="text-md font-bold">£{this.state.estimated_quote}</h5>
          </div>
          {/* <div className="flex justify-between py-0.5">
            <h5 className="text-md">VAT</h5>
            <h5 className="text-md">£{this.state.vat_val}</h5>
          </div>
          <div className="flex justify-between py-0.5">
            <h5 className="text-lg uppercasee">TOTAL</h5>
            <h5 className="text-lg font-bold">£{this.state.total_price}</h5>
          </div> */}
        </div>
        
      </div>
    </div>

  </div>
</section>
<Footer/>
        </div>
    )
    }
}
export default Booking;