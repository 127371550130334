import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import dummyimg from './assets/images/dummyimg.jpg'
import Header from './Header';
import Footer from './Footer';
import {vApis} from './Apis';
import sidebarBGImage from "./assets/images/bookingbanner.png";
import { ToastContainer, toast } from 'react-toastify';
import './assets/toast.css';
import InputMask from 'react-input-mask';
// import { parsePhoneNumberFromString } from "libphonenumber-js";


class ParcelBooking extends Component {
constructor(props) {
super(props);
this.state = {
  parcel_name: "",
  first_name: "",
    last_name: "",
    company: "",
    email: localStorage.getItem("bookingemail") ? localStorage.getItem("bookingemail") : "",
    telephone_number: "",
    address: "",
    house: "",
    country: localStorage.getItem("selectedcountry")?localStorage.getItem("selectedcountry"):"",
    city: "",
    postcode: localStorage.getItem("deliverypostcodes")?localStorage.getItem("deliverypostcodes"):"",
    countries:[],
    isValid: null,
    validationMessage: "",
};
}

componentDidMount(){
  this.fetchCountries()
}

fetchCountries = async () => {
  const allCountries = await vApis.fetchData('calculator/countries-iso2/');
  const countries = allCountries?.data?.countries;
  this.setState({ countries });
}

getQueryParameter = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};


validateUKPhoneNumber = (phoneNumber) => {
  console.log("phoneNumber====",phoneNumber)
  // Regular expression to validate UK phone number format
  const pattern = /^(\+447\d{9}|07\d{9})$/;

  if (!pattern.test(phoneNumber)) {
    return { isValid: false, message: "Your phone number is not in the correct format." };
  }

  // Additional logic to detect invalid numbers
  const invalidPrefixes = ["+447555", "07555"]; // Example invalid prefixes
  if (invalidPrefixes.some(prefix => phoneNumber.startsWith(prefix))) {
    return { isValid: false, message: "Your phone number is in the correct format, but the number is invalid." };
  }

  // If the format is correct and no invalid prefixes are found
  return { isValid: true, message: "Valid number" };
};


handleBookFormNumberChange = (event) => {
  const { name, value } = event.target;
  const phoneNumber = event.target.value;
  console.log("phoneNumber====",phoneNumber)
  const isValid = this.validateUKPhoneNumber(phoneNumber);
  console.log("isValid====",isValid)
  this.setState({ telephone_number: phoneNumber, isValid });

  this.setState({ [name]: value });
};

// validatePhoneNumber = (phoneNumber) => {
//   const parsedNumber = parsePhoneNumberFromString(phoneNumber);

//   if (!parsedNumber) {
//     return { isValid: false, message: "Your phone number is not in the correct format." };
//   }

//   if (!parsedNumber.isValid()) {
//     return { isValid: false, message: "Your phone number is in the correct format, but the number is invalid." };
//   }

//   return { isValid: true, message: "Valid number" };
// };

validatePhoneNumber = (phoneNumber) => {
  // Regular expression for basic international phone number validation
  const internationalPhoneRegex = /^\+(?:\d{1,3})\s?(?:\d{1,4}[\s.-]?)?(?:\d{1,4}[\s.-]?)?(?:\d{1,9})$/;
    
    if (internationalPhoneRegex.test(phoneNumber)) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: "Your phone number is not valid." };
    }
};

handleBookFormInputChange = (event) => {
  console.log("handleBookFormInputChange===")
  const { name, value } = event.target;
  
  
    this.setState({ [name]: value });
  
  
};
notifySuccess = () => {
  toast.success('Congratulations! Your vehicle booking has been successfully confirmed', {
    position: "top-right"
  });
};

notifyError = (msg) => {
  toast.error(msg, {
    position: "top-right"
  });
};

handleBookFormSubmit = async (event) => {
  console.log("handleBookFormSubmit")
  event.preventDefault();
  this.setState({isLoading:true})
  // Here you can use this.state to access form values
  const {
    parcel_name,
    first_name,
    last_name,
    company,
    email,
    telephone_number,
    address,
    house,
    city,
    country,
    postcode,
  } = this.state;
  const tosChecked = document.getElementById('tos1').checked;
  const prohibitedChecked = document.getElementById('prohibited').checked;

  // Validation checks
  if (!first_name) {
      alert("First Name is required");
      this.setState({isLoading:false})
      return;
  }
  if (!last_name) {
      alert("Last Name is required");
      this.setState({isLoading:false})
      return;
  }
  if (!email) {
      alert("Email is required");
      this.setState({isLoading:false})
      return;
  }
  if (!tosChecked) {
      alert("You must accept the Terms and Conditions");
      this.setState({isLoading:false})
      return;
  }
  if (!prohibitedChecked) {
      alert("You must acknowledge the Prohibited Items");
      this.setState({isLoading:false})
      return;
  }
 
  let dataBooking = JSON.stringify(
    {

      parcel: {
        name: parcel_name,
        first_name: first_name,
        last_name: last_name,
        company_name: company,
        address: address,
        house_number: house,
        city: city,
        postal_code: postcode,
        telephone: telephone_number,
        request_label: true,
        email: email,
        country: country,
        shipment: {
          id: Number(this.getQueryParameter("shippingid"))
        },
        data: {},
        weight: localStorage.getItem("weight"),
        length: localStorage.getItem("length"),
        width: localStorage.getItem("width"),
        height: localStorage.getItem("height"),
        to_service_point: localStorage.getItem("servicepoint"),
        price: localStorage.getItem("quoteestimation")
      } 
    }
  )



  console.log("dataBooking===",dataBooking);
  console.log("dataBooking json===",JSON.parse(dataBooking))
  let responseBookingForm;
  console.log("checking username on booking stage-------------------",localStorage.getItem("username"))
  if(localStorage.getItem("username")!="" && localStorage.getItem("username")!=null){
    responseBookingForm = await vApis.postData('parcels/create-parcel-user/',dataBooking);
  }
  else{
    responseBookingForm = await vApis.postData('parcels/create-parcel-guest/',dataBooking);
  }
console.log("responseBookingForm=======================",responseBookingForm)
  if(responseBookingForm?.data?.status===true){
    this.notifySuccess();
    this.setState({isLoading:false})
    localStorage.setItem("bookingid",responseBookingForm?.data?.booking_id);
    localStorage.setItem("bookingemail",responseBookingForm?.data?.email);
    localStorage.setItem("sendcloud",true);
    localStorage.removeItem("outofhoursmsg")
    //localStorage.setItem("quoteestimation",responseBookingForm?.data?.total);
    // localStorage.setItem("outofhoursmsg",responseBookingForm?.data?.msg);
    // localStorage.setItem("outofhours",responseBookingForm?.data?.out_of_hour);
    // localStorage.setItem("congestion",responseBookingForm?.data?.congestion);
    window.location.href = '/pay';
  }
  else {
    console.log("false sta")
    this.notifyError(responseBookingForm?.data?.message);
  }
};

render() {
  const { telephone_number, isValid, validationMessage } = this.state;
    return (
        <div className="page-container">
            <Header />
            <ToastContainer />
            <section className="ftco-about">
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${sidebarBGImage})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Booking <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Booking</h1>
          </div>
        </div>
      </div>
    </section>
                <div className="container">

                <section className="custom-progress-2-container md:flex md:flex-row justify-between">
            
            <div className="parcel-booking-form">
          <form onSubmit={this.handleBookFormSubmit}>
          <div className="custom-row">
              <div className="flex flex-col">
                <label className="title font-light mb-0.5">Parcel Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" className="text-input rounded-md" name="parcel_name" value={this.state.parcel_name} onChange={this.handleBookFormInputChange}/>
              </div>
              
            </div>
              <div className="sm:flex custom-row">
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" className="text-input rounded-md" name="first_name" value={this.state.first_name} onChange={this.handleBookFormInputChange}/>
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
                <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" className="text-input rounded-md" autoComplete="off" name="last_name" value={this.state.last_name} onChange={this.handleBookFormInputChange}/>
              </div>
            </div>
            <div className="sm:flex custom-row">
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">Company </label>
                <input type="text" maxLength="60" className="text-input rounded-md" autoComplete="off" name="company" value={this.state.company} onChange={this.handleBookFormInputChange}/>
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
                <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span>
                </label>
                

                <input type="email" maxLength="255" className="text-input rounded-md" autoComplete="off" name="email" value={this.state.email} onChange={this.handleBookFormInputChange}/>
              </div>
            </div>
            <div className="sm:flex custom-row">
              {/* <div className="flex flex-col w-full sm:w-1/2 sm:mr-3 mb-0 md:mb-0">
                <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span>
                </label>
                <input type="email" maxLength="255" className="text-input rounded-md" autoComplete="off" name="email" value={this.state.email} onChange={this.handleBookFormInputChangeFields}/>
              </div> */}
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">Telephone number <span className="text-red-500">*</span>
                </label>

                <input
          type="tel"
          id="phone"
          className="text-input rounded-md"
          name="telephone_number"
          value={telephone_number}
          onChange={this.handleBookFormInputChange}
          placeholder="Enter international phone number"
        />
        
        {/* {isValid !== null && <p style={{ color: isValid ? "green" : "red" }}>{validationMessage}</p>} */}
                {/* <input type="tel" maxLength="20" min="0" className="text-input rounded-md" autoComplete="off" name="telephone_number" value={this.state.telephone_number} onChange={this.handleBookFormInputChange}/> */}
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">Address<span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="32" className="text-input rounded-md" placeholder="" autoComplete="off" name="address" value={this.state.address} onChange={this.handleBookFormInputChange}/>
        </div>
</div>
<div className="sm:flex custom-row">
              
        <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
          <label className="title font-light mb-0.5">House</label>
          <input type="text" maxLength="100" className="text-input rounded-md" placeholder="" autoComplete="off" name="house" value={this.state.house} onChange={this.handleBookFormInputChange}/>
        </div>
        {/* <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">Country <span className="text-red-500">*</span>
          </label>
          <select
                        className="text-input rounded-md"
                        name="country"
                        value={this.state.country}
                        onChange={this.handleBookFormInputChange}
                        required
                      >
                        <option value="" disabled className='country-select-color'>Select Country</option>
                        {this.state.countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
          
        </div> */}
        <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="40" className="text-input rounded-md" placeholder="" autoComplete="off" name="city" value={this.state.city} onChange={this.handleBookFormInputChange}/>
        </div>
            </div>
          
          <div className="flex flex-col">
                <div>
                  <input type="checkbox" className="mr-2" id="prohibited" />
                  <label htmlFor="prohibited" id="tnc-label"> Non Alcohalic Items
                    <span className="text-red-500"> *</span>
                  </label>
                </div>
          
              </div>
          
                <div className="flex flex-col">
                <div>
                  <input type="checkbox" className="mr-2" id="tos1" />
                  <label htmlFor="tos1" id="tnc-label">I have read and accepted the <a href="/terms" target="_blank" className="text-green-primary text-base">T&amp;Cs</a>
                    <span className="text-red-500"> *</span>
                  </label>
                </div>
          
              </div>
              <div className="payment-box">
                
                <div className="mt-4 mb-4">
                  
                  <button className="custom-green-button default-bg-color" type='submit'>Book Now</button>
                 
                </div>
              </div>
              </form>
           </div>
           </section>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default ParcelBooking;
        