import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Carousel } from '3d-react-carousal';

const CustomCarousel = ({ vehicles, onSlideChange }) => {
    const [isPaused, setIsPaused] = useState(false);
    const [autoplay, setAutoplay] = useState(true);
    const prevVehiclesRef = useRef(vehicles);

    useEffect(() => {
        prevVehiclesRef.current = vehicles;
    }, [vehicles]);

    useEffect(() => {
        setAutoplay(!isPaused);
    }, [isPaused]);

    const memoizedSlides = useMemo(() => (
        vehicles?.map(slide => (
            <img key={slide.id} src={slide.image} alt={slide.vehicle_type} />
        ))
    ), [vehicles]);

    const memoizedOnSlideChange = useMemo(() => {
        return (value) => {
            try {
                if (value != null) {
                    const currentIndex = value % (vehicles.length || 1);
                    onSlideChange(currentIndex);
                }
            } catch (error) {
                console.error("An error occurred in memoizedOnSlideChange:", error);
            }
        };
    }, [vehicles, onSlideChange]);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Carousel
                slides={memoizedSlides}
                autoplay={autoplay}
                interval={6000} // Adjust this value to decrease the speed (milliseconds)
                arrows={true}
                onSlideChange={memoizedOnSlideChange}
            />
        </div>
    );
};

export default CustomCarousel;
