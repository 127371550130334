import React, { Component } from 'react';
import './assets/CookieConsent.css';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: !localStorage.getItem('cookieConsent')
    };
  }

  handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    this.setState({ isVisible: false });
  };

  handleDeny = () => {
    localStorage.setItem('cookieConsent', 'denied');
    this.setState({ isVisible: false });
  };

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <div className="cookie-consent">
        <p>We use cookies to improve your experience on our site. By using our site, you accept our use of cookies. <a href="/cookiepolicy">Learn more</a>.</p>
        <button className="btn btn-primary" onClick={this.handleAccept}>Accept</button>
        <button className="btn btn-secondary" onClick={this.handleDeny}>Deny</button>
      </div>
    );
  }
}

export default CookieConsent;
