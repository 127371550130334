import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import dummyimg from './assets/images/dummyimg.jpg'
import Header from './Header';
import Footer from './Footer';

class CourierList extends Component {
constructor(props) {
super(props);
this.state = {
    shippingMethods: [],
    filteredMethods: [],
    weight: localStorage.getItem('weight') || 1,
    carriers: [],
    loading: true,
      error: null,
};
}

componentDidMount() {
    this.fetchShippingMethods();
  }

  /*fetchShippingMethods = () => {
    fetch('https://www.parcellodge.com/api/parcels/retrieve-shipping_methods?from_postal_code=E1%207AX&isReturn=False&to_postal_code=E1%207AX&to_country=GB&sender_address=546596')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data.data.shipping_methods); 
        if (data && Array.isArray(data.data.shipping_methods)) {
           localStorage.setItem('servicepoint', data?.data?.to_service_point);
          this.setState({ shippingMethods: data.data.shipping_methods }, () => {
            this.filterShippingMethods(Number(this.state.weight));
          });
        } else {
          console.error('Fetched data is not an array:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  };*/
  fetchShippingMethods() {
    const { weight } = this.state;
    const fromPostalCode = localStorage.getItem('collectionpostcodes');
    const isReturn = localStorage.getItem('isReturn') === 'true';
    const toCountry = "GB";

    const url = `https://www.parcellodge.com/api/parcels/retrieve-shipping_methods?from_postal_code=${fromPostalCode}&to_country=${toCountry}&weight=${weight}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({
          carriers: data.data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          loading: false,
        });
      });
  }

//   filterShippingMethods = (weight) => {
//     const roundedWeight = Math.round(weight);
//     const filteredMethods = this.state.shippingMethods.filter(method => {
//       const roundedMaxWeight = Math.round(parseFloat(method.max_weight));
//       return roundedWeight === roundedMaxWeight;
//     });
//     console.log('Filtered Methods:', filteredMethods); 
//     this.setState({ filteredMethods });
//   };
filterShippingMethods = (weight) => {
  const preciseWeight = parseFloat(weight.toFixed(2));  // Ensure weight is treated with decimal precision
  const filteredMethods = this.state.shippingMethods.filter(method => {
    const minWeight = parseFloat(method.min_weight-1);
    const maxWeight = parseFloat(method.max_weight+1);
    console.log("preciseWeight============",preciseWeight);
    console.log("minWeight============",minWeight)
    console.log("maxWeight============",maxWeight)

    console.log("returning weight=====",preciseWeight >= minWeight && preciseWeight <= maxWeight)
    return preciseWeight >= minWeight && preciseWeight <= maxWeight;
  });
  console.log('Filtered Methods:', filteredMethods); 
  this.setState({ filteredMethods });
};


  
render() {
  const { carriers, loading, error } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
               
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            <div className="container mt-10">
                            <section className="shipping-methods">
        <div className="container px-4 px-lg-5 mt-5">
        <div class="row d-flex justify-content-center align-items-center h-100"><div class="col-12"><div class="d-flex justify-content-between align-items-center mb-2"><h2>Shipping Methods</h2></div></div></div>
        
        
        <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 ">
          {console.log("carriersssssssss===",carriers)}
        {carriers.map((carrier, index) => (
          <div key={index} className="col-md-4 mb-8">
            <h3>{carrier.carrier}</h3>
            {carrier.shipments.map((shipment) => (
              <div key={shipment.id} className="shipment-details">
                <p>{shipment.name}</p>
                <ul>
                  {shipment.countries.map((country) => (
                    <li key={country.id}>
                      Price: £{country.price.toFixed(2)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
        
        {/* <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 justify-content-center">
        {this.state.filteredMethods.map(method => (
  <div className="col-md-4 mb-8" key={method.id}>
    <div className="card h-100">
      <img className="card-img-top" src={dummyimg} alt="..." />
      <div className="card-body p-2">
        <div className="text-center">
          <h5 className="fw-bolder">{method.name}</h5>
          <div>{method.countries[0].price} £</div>
          <div>Min Weight: {method.min_weight}kg</div>
          <div>Max Weight: {method.max_weight}kg</div>
        </div>
      </div>
      <div className="card-footer pb-2 pt-0 border-top-0 bg-transparent">
        <div className="text-center">
          <a
            className=""
            href={`/bookparcel?shippingid=${method.id}`}
            onClick={() => 
                localStorage.setItem('quoteestimation', method.countries[0].price)   
            }
          >
           <button className='btn btn-secondary py-2 px-3'>Book Now</button> 
          </a>
        </div>
      </div>
    </div>
  </div>
))}

</div> */}

        </div>
      </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default CourierList;
        