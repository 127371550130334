import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class UPS extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            <div className="container mt-10">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="display-4">Welcome</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default UPS;
        