import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

class PriceCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCollectionCode: '',
      selectedDeliveryCode: '',
      selectedVehicleType: '',
      selectedVehicleName: '',
      emailEst: '',
      returnJourney: false,
      vehicleTypes: [],
      isLoading: false,
      resultCollectionCode: '',
      resultDeliveryCode: '',
      collectionCodeError: false,
      deliveryCodeError: false,
      vehicleTypeError: false,
      emailEstError: false,
    };
  }

  componentDidMount() {
    this.fetchVehicles();
  }

  loadGoogleMapsAPI = (callback) => {
    console.log("loadGoogleMapsAPI")
    const existingScript = document.getElementById('googleMaps');
console.log("existingScr--------",existingScript)
    if (!existingScript) {
        console.log("creating google api")
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDMdKrh0Fq2yvDYPw1PxEY9WyXw3aHT6EY&libraries=places`;
        script.id = 'googleMaps';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    } else if (callback) {
        callback();
    }
};

  validatePostcode = async (e) => {
    console.log("validatePostcode")
    e.preventDefault();
    this.loadGoogleMapsAPI(() => {
      const { selectedCollectionCode, selectedDeliveryCode } = this.state;
      const geocoder = new window.google.maps.Geocoder();
console.log("geocodes")
      const validate = (address) => {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ 'address': address, 'region': 'uk' }, (results, status) => {
            console.log("geocodes 1")
            if (status === window.google.maps.GeocoderStatus.OK) {
              const isInUK = results[0].formatted_address.includes("UK");
              if (isInUK) {
                resolve("Valid postcode");
              } else {
                resolve("Invalid postcode.");
              }
            } else {
              resolve("Invalid postcode.");
            }
          });
        });
      };

      Promise.all([
        validate(selectedCollectionCode),
        validate(selectedDeliveryCode)
      ]).then(([resultCollectionCode, resultDeliveryCode]) => {
        this.setState({ resultCollectionCode, resultDeliveryCode }, () => {
          if (resultCollectionCode === "Valid postcode" && resultDeliveryCode === "Valid postcode") {
            this.handleSubmit();
          }
        });
      });
    });
  };

  handleInputCollectionChange = (event) => {
    const query = event.target.value;
    this.setState({ selectedCollectionCode: query, isDropdownOpen: false });
  };

  handleInputDeliveryChange = (event) => {
    const query1 = event.target.value;
    this.setState({ selectedDeliveryCode: query1, isDropdownOpenDelivery: false });
  };

  handleVehicleTypeChange = (event) => {
    const selectedId = event.target.value;
    const selectedVehicle = this.state.vehicleTypes.find(vehicle => vehicle.id === selectedId);
    this.setState({ 
      selectedVehicleType: selectedId, 
      selectedVehicleName: selectedVehicle.vehicle_type, 
      vehicleTypeError: !event.target.value 
    });
    localStorage.setItem("selectedvehicle", selectedId);
  };

  handleEmailEstChange = (event) => {
    this.setState({ emailEst: event.target.value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ returnJourney: event.target.checked });
  };

  handleSubmit = async () => {
    console.log("handle submit")
    const { selectedCollectionCode, selectedDeliveryCode, selectedVehicleName, emailEst } = this.state;

    let collectionCodeError = !selectedCollectionCode;
    let deliveryCodeError = !selectedDeliveryCode;
    let vehicleTypeError = !selectedVehicleName;
    let emailEstError = !emailEst;

    if (!selectedCollectionCode && !selectedDeliveryCode && !selectedVehicleName && !emailEst) {
      collectionCodeError = true;
      deliveryCodeError = true;
      vehicleTypeError = true;
      emailEstError = true;
    }

    this.setState({
      collectionCodeError,
      deliveryCodeError,
      vehicleTypeError,
      emailEstError
    });

    if (collectionCodeError || deliveryCodeError || vehicleTypeError || emailEstError) {
      return;
    }

    this.setState({
      collectionCodeError: false,
      deliveryCodeError: false,
      vehicleTypeError: false,
      emailEstError: false
    });

    this.setState({ isLoading: true });

    let est_data = JSON.stringify({
      postcodes: [selectedCollectionCode, selectedDeliveryCode],
      vehicle_type: selectedVehicleName,
      return_journey: this.state.returnJourney,
      email: this.state.emailEst,
    });

    const response = await vApis.postData('calculator/calculate-quote/', est_data);

    localStorage.setItem("quoteestimation", response?.data.quote);
    localStorage.setItem("collectionpostcodes", this.state.selectedCollectionCode);
    localStorage.setItem("deliverypostcodes", this.state.selectedDeliveryCode);
    localStorage.setItem("quoteestimation", response?.data.quote);
    localStorage.setItem("vat", response?.data.VAT);
    localStorage.setItem('extras', JSON.stringify(response?.data.Extras));
    localStorage.setItem('quoteid', JSON.stringify(response?.data.quote_id));
    localStorage.setItem('vehicledistancesurcharge', response?.data.vehicle_distance_surcharge);

    // if (response?.data.is_out_of_hour !== "In Hours") {
    //   localStorage.setItem("outofhours", response?.data.is_out_of_hour);
    // } else if (response?.data.is_out_of_hour === "In Hours") {
    //   localStorage.removeItem("outofhours");
    // }

    localStorage.setItem("distance", response?.data.distance);

    window.location.href = '/booking';
  };

  fetchVehicles = async () => {
    const allVehicles = await vApis.fetchData('calculator/vehicles/');
    const vehicleTypes = allVehicles?.data?.vehicles_list;
    this.setState({ vehicleTypes });
  };

  render() {
    const { selectedCollectionCode, selectedDeliveryCode, emailEst, returnJourney, vehicleTypeError } = this.state;

    return (
      <div className="price-calculator-popup">
        <div className="request-form ftco-animate bg-primary">
            {/* <div id="closeCalculator">Close</div> */}
          <form onSubmit={this.validatePostcode} className="request-form ftco-animate bg-primary">
            <h2>Get an Instant Quote</h2>
            <div className="autocomplete m-0">
              <label className="label select-label">Collection Postal Codes</label>
              <input
                type="text"
                value={selectedCollectionCode}
                onChange={this.handleInputCollectionChange}
                placeholder="Enter postal code"
              />
              <div className='form-group m-0'>
                <label className="label select-label red">{this.state.resultCollectionCode}</label>
              </div>
            </div>
            <div className="autocomplete m-0">
              <label className="label select-label">Delivery Postal Codes</label>
              <input
                type="text"
                value={selectedDeliveryCode}
                onChange={this.handleInputDeliveryChange}
                placeholder="Enter postal code"
              />
              <div className='form-group m-0'>
                <label className="label select-label red">{this.state.resultDeliveryCode}</label>
              </div>
            </div>
            <div className="form-group">
              <label className="label select-label">Vehicle Type</label>
              <Select
                className='vehicles-data'
                value={this.state.selectedVehicleType}
                onChange={this.handleVehicleTypeChange}
                displayEmpty
                error={vehicleTypeError}
              >
                <MenuItem value="" disabled>Select Vehicle Type</MenuItem>
                {this.state.vehicleTypes.map(vehicleType => (
                  <MenuItem key={vehicleType.id} value={vehicleType.id}>{vehicleType.vehicle_type}</MenuItem>
                ))}
              </Select>
            </div>
            <div className='form-group'>
              <label className="label select-label">Email</label>
              <TextField
                className='emailEst'
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={emailEst}
                onChange={this.handleEmailEstChange}
                displayEmpty
                error={this.state.emailEstError}
                helperText={this.state.emailEstError && "Email is required"}
              />
            </div>
            <div>
              <FormControlLabel
                className='return-journey-check'
                control={
                  <Checkbox
                    checked={returnJourney}
                    onChange={this.handleCheckboxChange}
                    name="returnJourneyCheckbox"
                    color="primary"
                  />
                }
                label="Return Journey"
              />
            </div>
            <div className="form-group">
              <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default PriceCalculator;
