import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class ProhibitedItems extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            <div className="container mt-10">
                                <div className="row">
                                    <div className="col-12">
                                    <div className="section">
        <h2>Prohibited Items</h2>
        <ul>
            <li>Live or Preserved Organisms: Includes all forms of animals and plants.</li>
            <li>Perishable Goods: All types of perishable foods and other perishable products.</li>
            <li>Electronics with Built-in Batteries: Such as certain toys, devices, and gadgets.</li>
            <li>Biological or Medical Samples</li>
            <li>Ceramics and Porcelain: Any items made from ceramic or porcelain.</li>
            <li>Compressed Air and Empty Cylinders</li>
            <li>Personal Care Products: Includes all cosmetics and hand sanitizers.</li>
            <li>Medical or Illegal Substances: Any drugs, prescriptions, tobacco products, or items deemed illegal in the exporting or importing countries.</li>
            <li>Hazardous Materials: Aerosols, explosives, flammable items, and other dangerous goods.</li>
            <li>Large or Oil-containing Vehicle Parts: Such as doors, engines, or parts containing fluids.</li>
            <li>Liquids and Gels: All forms of liquids, creams, oils, and gels.</li>
            <li>Monetary Items: Cash, cards, stamps, and other valuable financial documents.</li>
            <li>Weapons and Ammunition: Including replicas.</li>
            <li>Household Appliances: Large items like cooker hoods or hot tubs.</li>
            <li>Wood Products: Any item primarily made of wood.</li>
        </ul>
    </div>
    <div className="section">
        <h2>Items Protected for Loss Only</h2>
        <ul>
            <li>Antiques and Collectibles</li>
            <li>Flooring Materials: Including all types of wooden and laminate flooring.</li>
            <li>Coffee Equipment: Coffee machines, grinders, and roasters.</li>
            <li>Personal Vehicles: All forms of cycles and related equipment.</li>
            <li>Electronic Devices: Such as cameras, drones, and computing devices.</li>
            <li>Fishing Gear</li>
            <li>Home Furnishings: Furniture, knitting machines, and sewing machines.</li>
            <li>Models and Kits</li>
            <li>Musical Instruments: Must be sent in a hard case and boxed.</li>
            <li>Art and Wall Decor: Including canvas prints, framed pictures, and paintings.</li>
        </ul>
    </div>
    <div className="section">
        <h2>No-Protection Items</h2>
        <ul>
            <li>Ceramics and Glass: All items made from ceramics, glass, or similar fragile materials.</li>
            <li>Packaging Materials: Includes boxes and suitcases used for packaging.</li>
            <li>Precious Materials: All precious metals and stones, including those set in jewellery.</li>
            <li>Small Vehicle Parts: Includes lights and gearboxes.</li>
            <li>Personal Accessories: All types of watches and cosmetics.</li>
        </ul>
    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default ProhibitedItems;
        