import React, { Component } from 'react';
import { FaArrowRight } from 'react-icons/fa';

class ServiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { isHovered } = this.state;
    const { serviceName } = this.props;
    const { serviceImg } = this.props;
    const { id } = this.props;
    //console.log("serviceimg324234=====",serviceImg)
    return (
      <div
        className="col-md-2"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="services services-2 w-100 text-center">
          <div className="ser-img-cont d-flex align-items-center justify-content-center">
            {/* <span className="flaticon-wedding-car"></span> */}
           
            <img src={serviceImg} alt=""/>
          </div>
          <div className="text w-100">
            <h3 className="heading mb-2">{serviceName}</h3>
            {isHovered ? (
              <a href={`/service/?id=${id}`}>Read more</a>
            ) : (
              <p><FaArrowRight /></p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceComponent;
