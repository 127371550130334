import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import stripePromise from './stripe';
import { vApis } from './Apis';

import './assets/payment.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLock, faUser, faCommentAlt, faMapMarkerAlt, faCreditCard, faRoad, faCity, faEnvelope, faMapMarkedAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import './assets/toast.css';
import CircularProgress from '@material-ui/core/CircularProgress';

class PaymentForm extends Component {
  state = {
    email: '',
    amount: '',
    name: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isLoading: false,
    total_amount: 0,
    countries: [],
    country: "",
    paymentRequest: null,
    out_of_hours: localStorage.getItem("outofhours")? localStorage.getItem("outofhours") : "",
    congestion: localStorage.getItem("congestion")? localStorage.getItem("congestion") : 0
  };

  componentDidMount() {
    let getTotalAmount = localStorage.getItem("quoteestimation");
    let bookingEmail = localStorage.getItem("bookingemail");

    this.setState({ total_amount: getTotalAmount, email: bookingEmail });
    this.fetchCountries();

    // Set up PaymentRequest for Apple Pay and Google Pay
    const { stripe } = this.props;
    console.log("stripe====",stripe)
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: parseFloat(getTotalAmount) * 100, // amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
console.log("paymentRequest===",paymentRequest)
      paymentRequest.canMakePayment().then((result) => {
        console.log("result=====",result)
        if (result) {
          this.setState({ paymentRequest });
        }
      });

      paymentRequest.on('paymentmethod', async (ev) => {
        const { error, paymentIntent } = await stripe.confirmPayment(
          ev.data.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );
        
        if (error) {
          ev.complete('fail');
          console.error('Payment failed', error);
        } else {
          ev.complete('success');
          if (paymentIntent.status === 'requires_action') {
            const { error: actionError } = await stripe.confirmCardPayment(ev.data.clientSecret);
            if (actionError) {
              console.error('Payment failed', actionError);
            } else {
              console.log('Payment succeeded!');
            }
          } else {
            console.log('Payment succeeded!');
          }
        }
      });
    }
  }

  fetchCountries = async () => {
    const allCountries = await vApis.fetchData('calculator/countries/');
    const countries = allCountries?.data?.countries;
    this.setState({ countries });
  };

  notifySuccess = () => {
    toast.success('Congratulations! Your payment has been processed. You will receive a confirmation email shortly with details of your purchase', {
      position: "top-right"
    });
  };

  notifyError = (msg) => {
    console.log(msg);
    toast.error(msg, {
      position: "top-right"
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeCountry = (event) => {
    this.setState({ country: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { stripe, elements } = this.props;
    const { email, total_amount, address } = this.state;
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    try {
      let theBody = JSON.stringify({ 
        email: this.state.email, 
        amount: parseFloat(this.state.total_amount) * 100, 
        address: address, 
        booking_id: localStorage.getItem("bookingid"),
        sendcloud: localStorage.getItem("sendcloud"),
        //quote_id: localStorage.getItem("quoteid") ? parseFloat(localStorage.getItem("quoteid")) : 0
      });

      // if (localStorage.getItem("sendcloud") !== null && localStorage.getItem("sendcloud") === "false") {
      //   theBody.quote_id = localStorage.getItem("quoteid") ? parseFloat(localStorage.getItem("quoteid")) : 0
      // }
      let response;
      
      if(localStorage.getItem("username")!="" && localStorage.getItem("username")!=null){
        response = await vApis.postData('payments/create-payment-intent/user/', theBody);
      }
      else{
        response = await vApis.postData('payments/create-payment-intent/guest/', theBody);
      }
      console.log("response status====",response.data.status)
      if(response.data.status){
        const result = await stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              email: email,
              address: {
                city: this.state.city,
                line1: this.state.address,
                postal_code: this.state.postalCode,
                state: this.state.state,
                country: this.state.country,
              },
            },
          },
        })
        this.setState({ isLoading: false });
      if (result.error) {
        this.setState({ isLoading: false });
        this.notifyError(result.error.message	);
       // console.error(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        this.notifySuccess();
        setTimeout(() => {
          window.location.href = '/thankyou';
        }, 2000);
      }
      }
      else {
        this.setState({ isLoading: false });
        this.notifyError(response.data.message	);
      }

      
    } catch (error) {
      this.setState({ isLoading: false });
      this.notifyError(error.message	);
    }
  };

  render() {
    return (
      <section className="ftco-section contact-section show">
    <div className="container">
      <div id="payment-section">
        <ToastContainer />
        {this.state.isLoading && (
          <div className="loader-overlay">
            <CircularProgress className="loader" />
          </div>
        )}

{localStorage.getItem("outofhours") && localStorage.getItem("sendcloud")!=="true"?
        <div className='row'>
            <div className="out_of_hours_msg">
                  {localStorage.getItem("outofhoursmsg")}
                </div>
            </div>:""}

            {/* <div className='row'>
            <div className="congestion_msg">
                  {localStorage.getItem("congestion")}
                </div>
            </div> */}

        <div className="containerPay bg-light d-md-flex align-items-center">
          
          <div className="card box1 shadow-sm p-md-5 p-md-5 p-4">
            <div className="d-flex flex-column">
            
{localStorage.getItem("outofhours") && localStorage.getItem("sendcloud")!=="true"?
            <div className="d-flex align-items-center justify-content-between text mb-4">
                <span>Out of hours cost:</span>
                <span className="fas fa-dollar-sign">
                  <span className="ps-1">£{localStorage.getItem("outofhours")}</span>
                </span>
              </div>
              : ""
  }

{localStorage.getItem("congestion")?
            <div className="d-flex align-items-center justify-content-between text mb-4">
                <span>Congestion:</span>
                <span className="fas fa-dollar-sign">
                  <span className="ps-1">£{localStorage.getItem("congestion")}</span>
                </span>
              </div>
              : ""
  }


              <div className="d-flex align-items-center justify-content-between text mb-4">
                <span>Total</span>
                <span className="fas fa-dollar-sign">
                  <span className="ps-1">£{this.state.total_amount}</span>
                </span>
              </div>
              <div className="border-bottom mb-4"></div>
              <div className="d-flex align-items-center justify-content-between text mt-5">
                <div className="d-flex flex-column text">
                  <span>Customer Support:</span>
                  <span>online chat 24/7</span>
                </div>
                <div className="btn btn-primary rounded-circle">
                  <span><FontAwesomeIcon icon={faCommentAlt} /></span>
                </div>
              </div>
            </div>
          </div>
          <div className="card box2 shadow-sm">
            <div className="d-flex align-items-center justify-content-between p-4">
            </div>
            <form onSubmit={this.handleSubmit} className="payment-form">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column px-md-5 px-4 mb-4">
                    <span>Name</span>
                    <div className="inputWithIcon">
                      <input
                        className="form-control text-uppercase card-title"
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder='Full name on card'
                        required
                      />
                      <span><FontAwesomeIcon icon={faUser} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex flex-column pl-md-5 px-md-0 px-4 mb-4">
                    <span>City</span>
                    <div className="inputWithIcon">
                      <input
                        className="form-control text-uppercase card-title"
                        type="text"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange}
                        placeholder='City'
                        required
                      />
                      <span><FontAwesomeIcon icon={faCity} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex flex-column pr-md-5 px-md-0 px-4 mb-4">
                    <span>Postal Code</span>
                    <div className="inputWithIcon">
                      <input
                        className="form-control text-uppercase card-title"
                        type="text"
                        name="postalCode"
                        value={this.state.postalCode}
                        onChange={this.handleChange}
                        placeholder='Postal Code'
                        required
                      />
                      <span><FontAwesomeIcon icon={faEnvelope} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex flex-column pl-md-5 px-md-0 px-4 mb-4">
                    <span>State</span>
                    <div className="inputWithIcon">
                      <input
                        className="form-control text-uppercase card-title"
                        type="text"
                        name="state"
                        value={this.state.state}
                        onChange={this.handleChange}
                        placeholder='State'
                        required
                      />
                      <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex flex-column pr-md-5 px-md-0 px-4 mb-4">
                    <span>Country</span>
                    <div className="inputWithIcon">
                      <select
                        className="form-control text-uppercase card-title country-drop"
                        name="country"
                        value={this.state.country}
                        onChange={this.handleChangeCountry}
                        required
                      >
                        <option value="" disabled className='country-select-color'>Select</option>
                        {this.state.countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <span><FontAwesomeIcon icon={faGlobe} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex flex-column px-md-5 px-4 mb-4">
                    <span>Address</span>
                    <div className="inputWithIcon">
                      <input
                        className="form-control text-uppercase card-title"
                        type="text"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleChange}
                        placeholder='Address'
                        required
                      />
                      <span><FontAwesomeIcon icon={faRoad} /></span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex flex-column px-md-5 px-4 mb-4">
                    <span>Credit Card</span>
                    <div className="inputWithIcon">
                      <CardNumberElement className="card-element form-control" />
                      <span><FontAwesomeIcon icon={faCreditCard} /></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column pl-md-5 px-md-0 px-4 mb-4">
                    <span>Expiration</span>
                    <div className="inputWithIcon">
                      <CardExpiryElement className="card-element form-control" />
                      <span><FontAwesomeIcon icon={faCalendarAlt} /></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column pr-md-5 px-md-0 px-4 mb-4">
                    <span>Code CVV</span>
                    <div className="inputWithIcon">
                      <CardCvcElement className="card-element form-control" />
                      <span><FontAwesomeIcon icon={faLock} /></span>
                    </div>
                  </div>
                </div>

                <input
                  className="hidden-amount"
                  type="hidden"
                  name="name"
                  value={this.state.total_amount}
                />
                <div className="col-12">
                  <div className="btn btn-primary w-100">
                    <button type="submit" disabled={!this.props.stripe}>
                      Pay £{this.state.total_amount}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {console.log("this.state.paymentRequest===",this.state.paymentRequest)}
            {this.state.paymentRequest && (
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column px-md-5 px-4 mb-4">
                    <span>Apple Pay / Google Pay</span>
                    <div className="inputWithIcon">
                      <PaymentRequestButtonElement
                        options={{ paymentRequest: this.state.paymentRequest }}
                        className="payment-request-button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
      </section>
    );
  }
}

export default function InjectedPaymentForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <PaymentForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
