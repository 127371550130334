import React from 'react';
import homeWelcomeImg from "./assets/images/about.png";
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import Header from './Header';
import Footer from './Footer';

import aboutBGImage from './assets/images/about-banner.png';
import IndustryComponent from './IndustriesSection';
import { useParams } from 'react-router-dom';

const Terms = () => {
  
  
  return (
    <div id="terms-page">
        

<Header/>
      {/* <h1>{cityName}</h1> */}
  
      <section className="ftco-about mt-5">
        
<div className="container mt-5 termsp">
    <div className="text-center mb-4">
        <h1>Parcel Lodge Terms and Conditions</h1>
        <p>Parcel Lodge Ltd registered in England & Wales No. 11645682, VAT Registration No. 459 6209 59.
        Registered office and postal address: 20 Weston Street, Walsall, WS1 4EJ, West Midlands, UK.</p>
    </div>

    <div>
        <h3>Introduction</h3>
        <p>In Terms & Conditions of carriage Parcel Lodge Ltd unless otherwise stated means anybody employed by or subcontracted to Parcel Lodge Ltd for the purpose of carriage of goods on behalf of Parcel Lodge Ltd. The customer means the person who contracts for the services of Parcel Lodge Ltd.</p>
        <p>Parcel Lodge is not a common carrier and only carry goods under these conditions. Any business taken or any information, advice or services supplied by Parcel Lodge Ltd is undertaken or provided subject to these terms and conditions which shall constitute the terms of any contract between Parcel Lodge Ltd and the customer. These terms and conditions cannot be changed or varied unless by owner of the Parcel Lodge Ltd. These terms and conditions apply from the time of acceptance of the customer order by Parcel Lodge Ltd.</p>

        <h4>General Terms</h4>
        <ul>
            <li>Any instructions accepted by Parcel Lodge Ltd will at the absolute discretion, be carried out in whole or part by Parcel Lodge Ltd or subcontracted agent, if the circumstances are deemed suitable.</li>
            <li>Customers entering transactions of any kind will warrant that they are either the owners or authorised agents for the owners of the goods. Further that they accept these conditions not only for themselves but also as agents for and on behalf of all other persons who are or may also become interested in the goods.</li>
            <li>The customer is responsible for accurate labelling of goods including the provision of the goods delivery address and sender's address together with any notice that good are fragile and/or having special handling requirements.</li>
            <li>Subject to express instructions agreed between the customer and Parcel Lodge Ltd, Parcel Lodge Ltd reserve the right to determine the travelling route and procedure together with the matters of handling and storage of goods. Parcel Lodge Ltd reserve the right to deviate from the customer's instructions if it is deemed appropriate necessary.</li>
            <li>All offers and quotations by Parcel Lodge Ltd shall remain valid for a period of 24 hours for the same day and 7 days for pre-booked work, unless withdrawn by Parcel Lodge Ltd within that time.</li>
            <li>Client must be the owner of the goods or authorised by the owner of the goods and acting on behalf of a company. We may cancel the order if this is not met.</li>
        </ul>

        <h4>Payment Terms</h4>
        <p>All invoices to be paid 14 days from the date of invoice unless otherwise agreed in writing. Where charges remain outstanding after the agreed payment terms, Parcel Lodge Ltd will be entitled to charge a late payment fee according to £10/week after the due date. The same charges shall apply immediately to any returned (bounced) cheques which must then be cleared by a secure credit/debit card transaction. If invoices are not paid within the due period, we will stop supplying our services and all invoices will immediately become due and payable. Customers other than the registered company or private customers have to make payments in advance (before job booking confirmation) by secure card payment or by paypal.</p>

        <h4>Charges</h4>
        <p>All charges are standard charges at the time of contract. We may set up a trading account on your request with a credit limit, which we can increase and decrease from time to time. It is your responsibility to make the payment when you reach your credit limit before receiving further services. We may change, withdraw or request full payment of the credit balance at any time.</p>
        <p>Unless stated otherwise, all charges quoted are exclusive of value added tax or any other sales, use, import or export, value added or similar tax which, if applicable, shall be payable by you.</p>
        <p>Unless stated otherwise, all charges are exclusive of any fuel surcharges which may be claimed by us.</p>
        <p>Congestion, ULEZ, Tolls and parking charges are excluded from the quotation price (unless agreed) and will be added to actual price.</p>

        <h4>Loss & Damage, Carrying Goods, Claims etc.</h4>
        <p>All couriers are subcontracted and hence any claim of loss, damage to goods, non delivery or wrong delivery if the cause is proved as negligence or the default of Parcel Lodge Ltd. We will make every reasonable effort to comply with customer's requirements and to satisfy any agreement between the customer and us (Parcel Lodge Ltd).</p>
        <p>It shall be the responsibility of the customer to satisfy him/herself that the vehicle offered to carry the goods is suitable for their load. Parcel Lodge Ltd will not accept any liability whatsoever for any claim arising from any claimed unsuitability of the vehicle offered. Parcel Lodge Ltd will not accept any liability whatsoever for any delays to goods, where is delay is of any other cause than the negligence of Parcel Lodge Ltd.</p>
        <p>We shall not be liable for any detention or consequential loss, damage or deterioration arising except where the owner or their agents have given specific notice in writing to us, outlining the nature of the goods to be carried, their purpose of transit and an agreed time schedule for delivery. Such liability by Parcel Lodge Ltd shall only be accepted if the cause for detention and its consequential loss, damage or deterioration was as direct result of negligence by us or our agents or our subcontractors.</p>
        <p>Parcel Lodge Ltd will not be responsible for any loss or damage caused by improper packaging, or insufficient labelling, or improper addressing.</p>
        <p>Any claims against Parcel Lodge Ltd must be lodged in writing within seven days of the delivery occurring and a quantified claim lodged within 28 days. Except by special arrangements in writing, Parcel Lodge Ltd will not accept, handle or deal with any noxious, dangerous, hazardous, inflammable or explosive goods or any goods likely to cause damage. Should the customer (knowingly or unknowingly) prepare any such goods for transit or cause handling or any other dealing by us, then Parcel Lodge Ltd will not be liable for any loss, damage or detention of such goods nor from any resultant claims against persons or property that results. All such liabilities and claims will revert to the customer or their agent.</p>
        <p>Except by special arrangement in writing, Parcel Lodge Ltd will not accept for transit or deal with bullion, precious stones, jewelry, valuables, antiques, livestock, animals, reptiles or plants. Should a customer deliver any such goods for carriage by us without declaring them, Parcel Lodge Ltd will be liable for any consequent loss, damage, deterioration or liability arising.</p>
        <p>Parcel Lodge Ltd will not deal with, handle or transit any form of illegal drugs, stolen goods, or products from endangered species. Should it come to the notice of Parcel Lodge Ltd any such goods be placed in our hands, they will be handed to the authorities. Parcel Lodge Ltd will also hand over the customer's names for the matter to be handled by authorities in the due course of the law. There will be no exceptions to the clause and Parcel Lodge Ltd will not accept any liability for the inadvertent handling or carriage of such items.</p>
        <p>WE ARE NOT TRANSPORTING ANY KIND OF DRINKABLE ALCOHOL OR HARAM GOODS/ITEMS ACCORDING TO SHARIA LAW.</p>
        <p>These terms and conditions shall be subject to and construed in accordance with English law and any proceedings in relation thereto shall be subject to the exclusive jurisdiction of the English courts.</p>

        <h4>Sub-contracting</h4>
        <p>You acknowledge and agree that we may subcontract provision of the services to the carrier or third party. We will not be responsible and liable for the acts and omissions of any carrier (third party) that we may engage to perform the services.</p>
        <p>The carriage of any goods by rail, sea, inland waterway or air arranged by us shall, in addition to these Terms, be subject to the terms and conditions of the relevant carrier.</p>

        <h4>Term and Termination</h4>
        <p>Either party may terminate an Order for storage and fulfilment by serving fourteen (14) days notice to terminate, and all amount payable under the order shall immediately become due.</p>
        <p>In the event that your account is terminated, you will pay immediately all outstanding amounts and your personal data will be deleted from our operational systems, however we will retain a copy in accordance with applicable financial legislation.</p>

        <h4>Services Terms and Conditions</h4>
        <p>These Services Terms incorporate the Standard Terms and Conditions available on our website. Unless otherwise stated, all definitions in the Standard Terms and Conditions apply to these Services Terms.</p>

        <h4>Services</h4>
        <p>These Terms apply to each of the following services provided by Parcel Lodge: (i) Same Day Delivery; (ii) Storage and Fulfillment; and (iii) Next Day and International Delivery Services.</p>

        <h4>Service Levels and Compensation</h4>
        <p>Your Order will outline the Service Levels offered for the relevant service. Compensation for any service issues, such as damage to goods while in our possession, is payable at our discretion and will be based on actual cost.</p>

        <h4>Dangerous Goods</h4>
        <p>Parcel Lodge does not handle Dangerous Goods. Disclosure of Dangerous Goods is required at the time of order placement. If an order involving Dangerous Goods is accepted, it will be handled by an approved Carrier under their specific terms.</p>

        <h4>Same Day Delivery</h4>
        <h5>Loading/Unloading</h5>
        <p>Parcel Lodge is not required to provide power, equipment, or labor for loading or unloading consignments. You must specify any special equipment needed for handling your consignment at the time of order placement. We will inform you if we can provide the required equipment or if you need to arrange it yourself. You indemnify Parcel Lodge against any claims related to damage or injury caused by equipment you provide for handling your consignment unless caused by our negligence.</p>

        <h5>Evidence of Possession of Consignment</h5>
        <p>You may request documentation acknowledging our possession of your consignment. However, such documentation cannot be used as proof of the consignment's condition or contents upon receipt.</p>

        <h5>Transit</h5>
        <p>Transit begins when we take possession of the consignment and ends upon delivery, except under specific conditions outlined in the terms, such as inaccessible delivery locations or unclaimed goods.</p>

        <h5>Goods In Transit</h5>
        <p>Parcel Lodge Ltd is unable to assess the value of goods to carried prior to get the collection point. Parcel Lodge Ltd will assess the value of the goods to be a maximum of £1000, unless specifically stated by the customer. Our maximum liability is limited to £1000 per delivery and insure to a maximum value of £12,000 per delivery via their own goods in transit insurance. If the value of goods to be carried is to be greater than £12,000, the customer must either notify us in good time to provide a courier with additional adequate insurance cover, or it will be assumed that he/she has otherwise insured the goods separately and hence any liability or claims will revert to the customer or their agent.</p>

        <h5>Undelivered/Unclaimed Goods</h5>
        <p>If unable to deliver a consignment, we will attempt to notify you and hold the consignment for a reasonable period before it may be sold to recover costs.</p>

        <h5>Liability for Loss or Damage</h5>
        <p>Parcel Lodge is not liable for loss or damage to consignments caused by inadequate packaging, addressing, or handling by the consignee. Our liability for any loss or damage is limited as outlined in our Standard Terms and Conditions.</p>

        <h5>Limitation of Liability</h5>
        <p>Our total liability for Same Day Services will not exceed the lesser of the replacement value of the goods, the cost of repair, or a specified monetary limit.</p>

        <h5>Disclaimers</h5>
        <p>Claims for damage or loss must be reported within specified time frames. We are not liable for losses caused by your non-compliance or external factors beyond our control.</p>

        <h5>Indemnification</h5>
        <p>You agree to cover any losses or claims against Parcel Lodge related to improper consignment handling or documentation errors on your part.</p>

        <h4>Storage and Fulfillment</h4>
        <h5>Storage Procedure</h5>
        <p>Storage starts when goods are received at our designated facility. You must notify us in advance before sending goods for storage.</p>

        <h5>Allocated Storage</h5>
        <p>You are allocated specific storage space, and charges apply if limits are exceeded. Inventory checks are available upon request.</p>

        <h5>Removing Goods from Storage; Lien</h5>
        <p>Advance notice is required to remove goods from storage. Goods will not be released until all outstanding charges are paid.</p>

        <h5>Disclaimers</h5>
        <p>We are not responsible for discrepancies in goods stored at our facilities.</p>

        <h4>Next Day and International Delivery</h4>
        <h5>Third Party Partners</h5>
        <p>We may subcontract parts of the service to Carriers, and such services will be governed by the Carrier's terms and conditions.</p>

        <h5>International Taxes and Duties</h5>
        <p>You are responsible for all applicable taxes and duties. You indemnify Parcel Lodge against any related claims.</p>

        <h5>Limitation of Liability</h5>
        <p>The liability of any engaged Carrier is defined in their terms and conditions.</p>

        <h5>Charges</h5>
        <p>All charges are determined by your local Parcel Lodge office and are subject to review on an annual basis.</p>

        <h5>Period of Validity</h5>
        <p>All rates and charges are reviewed annually to ensure they remain competitive and reflective of current market conditions.</p>

        <h5>Invoicing and Billing</h5>
        <p>Invoices will be raised and sent on a weekly basis for services provided in the previous week unless an alternative arrangement has been requested.</p>

        <h5>Insurance</h5>
        <p>Goods are insured up to a value of £1300.00 per tonne, in accordance with standard RHA (Road Haulage Association) Terms and Conditions.</p>

        <h5>Proof of Delivery (POD)</h5>
        <p>Proofs of Delivery are available upon request.</p>

        <h5>Claims</h5>
        <p>All claims are processed in accordance with the Parcel Lodge claims procedure, details of which can be obtained from your local office.</p>

        <h5>Waiting Time Charges</h5>
        <p>Waiting time charges are applicable as follows, based on the vehicle type:</p>
        <ul>
            <li>Small Van to Medium Wheel Base: £15 per hour after 30 minutes</li>
            <li>Long Wheel Base and Extra Long Wheel Base: £20 per hour after 30 minutes</li>
            <li>Luton: £20 per hour after 30 minutes</li>
            <li>7.5 Tonne: £30 per hour after 30 minutes</li>
            <li>18/26 Tonne: £40 per hour after 45 minutes</li>
            <li>Artic: £50 per hour after 90 minutes</li>
        </ul>

        <h5>Cancellation Charges</h5>
        <p>Cancellation fees depend on the vehicle type and the notice period given prior to collection:</p>
        <ul>
            <li>Small Van to Luton:</li>
            <ul>
                <li>Driver onsite: 100% charge</li>
                <li>1 to 30 minutes notice: £25 administrative fee</li>
                <li>Over 31 minutes notice: No charge</li>
            </ul>
            <li>7.5 Tonne to Artic:</li>
            <ul>
                <li>0 to 3 hours notice: 100% charge</li>
                <li>3:01 to 6:00 hours notice: 75% charge</li>
                <li>6:01 to 9:00 hours notice: 50% charge</li>
                <li>9:01 to 12:00 hours notice: 25% charge</li>
                <li>Over 12 hours notice: No charge</li>
            </ul>
        </ul>

        <h5>Additional Charges</h5>
        <p>Congestion, ULEZ (Ultra Low Emission Zone) charges are billed at the actual cost incurred.</p>
        <p>Additional drops are charged depending on the vehicle type and location:</p>
        <ul>
            <li>Small Van: £10 outside London, £25 inside London</li>
            <li>Luton to 7.5T: £25 outside London, £35 inside London</li>
            <li>18T/26T: £35 outside London, £45 inside London</li>
            <li>Artic: £40 outside London, £50 inside London</li>
        </ul>
        <p>Hand-balling charges vary by vehicle type:</p>
        <ul>
            <li>Small Van to Extra Long Wheel Base: £15 per hour</li>
            <li>Luton: £20 per hour</li>
            <li>7.5T: £35 per hour</li>
            <li>18T to Artic: £45 per hour</li>
        </ul>

        <h5>Rebooks, Returns & Refusals</h5>
        <p>Charges for reeboks, returns, or refusals will be calculated according to the Parcel Lodge costing model.</p>


        <h2><strong>Please see below links to our Carrier Terms &amp; Conditions</strong></h2><p><a href="http://www.dpd.co.uk/terms-and-conditions.jsp" target="_blank" rel="noopener"><strong>DPD Terms &amp; Conditions</strong></a></p><p><a href="http://www.dhl.co.uk/en/express/shipping/shipping_advice/terms_conditions.html" rel="noopener"><strong>DHL Terms &amp; Conditions</strong></a></p><p><a href="https://www.myhermes.co.uk/terms-and-conditions.html" rel="noopener"><strong>Evri Terms &amp; Conditions</strong></a></p><p><a href="https://www.dhlparcel.co.uk/en/business-users/support-hub/business-support/terms-conditions.html" rel="noopener"><strong>DHL Parcel UK Terms &amp; Conditions</strong></a></p><p><strong><a href="https://www.royalmail.com/terms-and-conditions" target="_blank" rel="noopener">Royal Mail Terms &amp; Conditions</a>&nbsp;</strong></p><p><strong><a href="https://www.yodel.co.uk/terms-and-conditions">Yodel Terms &amp; Conditions</a></strong></p>
    </div>
</div>


         
      </section>
    
    <Footer/>
    </div>
  );
};

export default Terms;
