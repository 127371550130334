import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";

import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";

import calendaricon from "./assets/images/calendar_icon.png";
import diffobj from "./assets/images/diffobj.png";


import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";

import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import  MapContainer  from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import industryImg from "./assets/images/image_6.jpg";
import IndustryBanner from './assets/images/becomedriver.jpg';
import ContactForm from './ContactForm';
// import serviceImg from '';

class BecomeDriver extends Component {
    constructor(props) {
      super(props);
      this.state = {
        serviceData: [],
        
      }
    }

    componentDidMount() {
        
      }

     
    render() {
        const { serviceData } = this.state;
  return (
    <div id="becomedriver-page">
        <Header/>

        <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${IndustryBanner})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Become a driver <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">{serviceData.Service}</h1>
          </div>
        </div>
      </div>
    </section>

        <section className="ftco-about">
			<div className="container">
				<div className="row no-gutters">
					
					<div className="col-md-12 wrap-about">
          <div class="container mt-5">
        {/* <div class="row">
            <div class="col-12">
                <h1 class="display-4">Rapid Response For Urgent Deliveries</h1>
                <p class="lead">Our Same Day Delivery service guarantees that your critical packages are delivered with the utmost urgency and precision. This service is perfect for time-sensitive documents, emergency parts, or last-minute gifts that need to arrive on the day they are sent. We ensure that your items are picked up and delivered within the same day, offering real-time tracking and personalized customer support throughout the process.</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <h2>Efficient, Reliable Door-To-Door Service</h2>
                <p>We pride ourselves on the reliability and speed of our Same Day Delivery service. Our expert couriers are equipped with the latest technology to provide fast and effective delivery solutions across the UK, ensuring that your package reaches its destination exactly when needed.</p>
            </div>
        </div> */}
        <div class="row mt-4">
            <div class="col-12">
                <h2>Join Our Team as a Same Day Delivery Driver</h2>
                <p>As a driver for our Same Day Delivery service, you play a crucial role in maintaining our reputation for rapid, reliable deliveries. Here’s what the job involves:</p>
                <h3>Job Responsibilities:</h3>
                <ul>
                    <li><strong>Customer Service & Communication:</strong> You will interact directly with customers, providing friendly service and handling any questions or concerns with professionalism.</li>
                    <li><strong>Safe Transportation & Product Handling:</strong> Ensure all packages are securely transported without damage.</li>
                    <li><strong>Vehicle Maintenance:</strong> Keep your delivery vehicle in excellent condition to guarantee safe and efficient deliveries.</li>
                    <li><strong>Timely Deliveries:</strong> Adherence to schedules is critical; ensure all deliveries are made promptly.</li>
                    <li><strong>Record Keeping:</strong> Maintain accurate records of all deliveries and related paperwork.</li>
                </ul>
                <h3>Physical Requirements:</h3>
                <p>Drivers may be required to carry and lift heavy packages.</p>
                <h3>Job Requirements:</h3>
                <ul>
                    <li><strong>Proof of Right to Work:</strong> Must be legally authorized to work in the job location.</li>
                    <li><strong>Valid Driver’s License:</strong> A clean driving record and a valid driver's license are essential.</li>
                    <li><strong>Punctuality and Reliability:</strong> We count on you to arrive on time and manage your delivery schedule effectively.</li>
                    <li><strong>Time Management Skills:</strong> Efficiently manage delivery routes and schedules.</li>
                    <li><strong>Communication Skills:</strong> Excellent communication skills are crucial for providing top-notch customer service.</li>
                </ul>
                <p>Become part of a team that values speed and reliability. Apply today and help us ensure that urgent deliveries are handled with the care and urgency they deserve.</p>
            </div>
        </div>
    </div>
					</div>
					
					
					
				</div>
			</div>
		</section>


        <section className="ftco-section contact-section">
      <div className="container">
        <div className="row d-flex mb-5 contact-info">
        	<div className="col-md-12">
        		<div className="row mb-5">
		          <div className="col-md-12">
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <h1> Get in Touch</h1>
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className=" w-100  rounded mb-2 text-center">
			          	
			            <p><a href="tel://1234567920">+44 333 2244 839</a></p>
                  <p><a href="tel://1234567920">+44 333 2244 839</a></p>
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className=" w-100 rounded mb-2 text-center">
			          
			            <p> <a href="mailto:sales@parcellodge.com" className='green-text'>sales@parcellodge.com</a></p>
			          </div>
		          </div>
		        </div>
          </div>
          
        </div>

        <div className="row d-flex mb-5 contact-info bg-green">
        
        <ContactForm/>
        
        </div>
        
      </div>
    </section>


<Footer/>


    </div>
  );
    }
}

export default BecomeDriver;
